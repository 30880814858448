import { Restriction } from "gbd-models";

export class AHDUtils {

    static isAHDMember() {
        return !this.hasRestriction(Restriction.SHM_AHD);
    }

    static isBioPlusMember() {
        return !this.hasRestriction(Restriction.SHM_BIO_PLUS);
    }

    static isAHDOrBioPlusMember() {
        return this.isAHDMember() || this.isBioPlusMember();
    }

    static hasRestriction(restriction: Restriction) {
        const restrictions = JSON.parse(sessionStorage?.sydMedMemberContext)?.restrictions ?? [];
        return restrictions.includes(restriction);
    }
}