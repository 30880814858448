import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { JsonContentService } from '../../../sydmed-shared/content-service/json-content.service';
import {BenefitsContent} from '../../benefits/interfaces/benefitsContent';
import {DocumentHubLabels} from '../models/labels';
import { Restriction } from 'gbd-models';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class DocumentsComponent implements OnInit {
  public header: string;
  public hasForms: boolean = false;
  @Input() benefitsContent: BenefitsContent
  documentHubContent: DocumentHubLabels
  constructor(
    private interCommunicationService: InterCommunicationService,     
    private jsonSvc: JsonContentService,
  ) {
   }

  ngOnInit(): void {
    this.jsonSvc.getJSON('benefits').subscribe((res:BenefitsContent) => {
      this.benefitsContent = res;
    });
    this.jsonSvc.getJSON('document-hub').subscribe((res:DocumentHubLabels) => {
      this.documentHubContent = res;
    });
    this.hasForms = !this.jsonSvc.hasRestriction(Restriction.PLAN_DOCUMENTS_AND_FORMS, this.jsonSvc.getRestrictions());
    this.header = this.hasForms ? 'Documents & Forms' : 'Documents';
    this.interCommunicationService.raiseEvent({
        title: 'HEADER_TITLE',
        message: this.header
      });
  }
}