import { PlatformLocation } from '@angular/common';
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IUrlParam, UrlHelper } from "@anthem/mbrportal/http";
import { AppSession } from '@anthem/mbrportal/main';
import { NavigationHelper } from "@anthem/mbrportal/utility";

@Injectable()
export class NavigationHelper2 extends NavigationHelper {
  constructor(
    private _router2: Router,
    _platformLocation: PlatformLocation,
    _urlHlpr: UrlHelper,
    @Inject(AppSession) _appSession: AppSession
  ) {
    super(_router2, _platformLocation, _urlHlpr, _appSession);
  }

  navigateTo(url: string, urlParams?: Array<IUrlParam>) : Promise<boolean> {    
    url = `/secure/${this.getNavigationUrl(url).replace(/^\/+/g, '')}`;
    super.navigateTo(url, urlParams);
    return Promise.resolve(true);
  }
}
