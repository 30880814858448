import { GetCareCoordinatorAPIResponse, CareTeamDetail, CareCoordinatorDetail, MemberData, CareTeamContent } from '../interfaces/careCoordinatorDetail';
import { Injectable } from '@angular/core';
import { PhoneNumPipe } from 'sydmed/libs/common-pipes/src/lib/phone-number/phone-num.pipe';
import { JsonContentService } from '../../../sydmed-shared/content-service/json-content.service';

@Injectable({
    providedIn: 'root'
})
export class CareTeamModel {
    public coCareCoordinatorList: CareCoordinatorDetail[] = [];
    public primaryCareCoordinators: CareCoordinatorDetail[];
    private na: string;

    constructor(private phoneNumPipe: PhoneNumPipe, public jsonContentService: JsonContentService) { }

    /**
    * @description buildCareCoordinatorDetail() responsible for getting formatted Care Coordinator detail
    * @param {GetCareCoordinatorAPIResponse} rawPcpDetail, {CareTeamContent} content 
    * returns CareTeamDetail
    */
    buildCareCoordinatorDetail(rawCareCoordinatorDetail: GetCareCoordinatorAPIResponse[], content: CareTeamContent): CareTeamDetail {
        const market = this.jsonContentService.getMarket();
        this.na = typeof content.notAvailable !== 'undefined' ? content.notAvailable : 'N/A';
        this.coCareCoordinatorList = [];
        this.primaryCareCoordinators = [];
        for (let i = 0; i < rawCareCoordinatorDetail.length; i++) {
            const careCoordinatorDetail: CareCoordinatorDetail = {
                email: rawCareCoordinatorDetail[i].email,
                name: this.format(rawCareCoordinatorDetail[i].firstName) + ' ' + this.format(rawCareCoordinatorDetail[i].lastName),
                phoneNumber: this.format(rawCareCoordinatorDetail[i].phone1) !== this.na? this.phoneNumPipe.transform(rawCareCoordinatorDetail[i].phone1) : '',
                program: rawCareCoordinatorDetail[i].program
              };

            if(rawCareCoordinatorDetail[i].isPrimary === 'yes'){
                this.primaryCareCoordinators.push(careCoordinatorDetail);
            }
            else {
                this.coCareCoordinatorList.push(careCoordinatorDetail);
            }
        }
        let careTeamDetail: CareTeamDetail = {
            primaryCareCoordinators: market === 'TNMCD'? this.primaryCareCoordinators : [this.primaryCareCoordinators[0]],
            coCareCoordinatorList: this.coCareCoordinatorList
        }
        return careTeamDetail;
    }

    /**
     * @description getMemberData() used to fetch logged in member's name and hcid from session
     * @retruns MemberData
     */
    getMemberData(): MemberData {
        let memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
        let memberData: MemberData;
        if (typeof (memberContext) !== undefined) {
            memberData = {
                memberName : this.format(memberContext.memberName.firstName + ' ' + memberContext.memberName.lastName),
                subscriberId : this.getSubscriberId(memberContext.hcid),
                restrictions: memberContext.restrictions
            }
        }
        return memberData;
    }

	/**
	 * @description getSubscriberId() Returns the subscriberID from the HCID
	 * 
	 * @param hcidWithPrefix
	 * @return subscriberId
	 */
    private getSubscriberId(hcidWithPrefix: string): string {
        let subscriberId = this.format(hcidWithPrefix);
		if (hcidWithPrefix.length == 12) {
			subscriberId = hcidWithPrefix.substring(3);
		}
		return subscriberId;
    }
    
    /**
     * @description format() used to check if a value is null, undefined or blank
     * @param {any} value
     * @retruns {any}
     */
    private format(value: any): any {
        if (!value) {
        value = this.na;
        }
        return value;
    }
}