<!-- Body for Interventions -->
<div *ngIf="isLoading">
  <uxd-spinner></uxd-spinner>
</div>

<div id="interventions" class="intervention-container" *ngIf="!isLoading && isInterventionModalVisible">
  <div class="intervention-close-container">
    <button class="intervention-close-icon" id="interventionsDelete" aria-label="Close Modal" (click)="closeModal('icon')">
      <span aria-hidden="true" class="motif-icon motif-delete"></span>
    </button>
  </div>
  <div *ngIf="!interventionMessageError">
    <div data-tcp-content-renderer-cmp [contentBlock]="interventionsData.content" (actionLinkClickEvent)="onCardClick($event)">
    </div>
    <div class="int-desc" *ngIf="isLiveChatEnabled && !isInterventionMessage(interventionId)">
      <p id="review-title" class="review-title" [innerHTML]="callInterventionLabels.needHelp"></p>
      <div class="footer-desc" tcp-content-transform-cmp [content]="callInterventionLabels.liveChatFooterContent" (click)="handleContentPlaceholderLiveChatLinkClickEvent()">
      </div>
    </div>
    <div class="intervention-desc" *ngIf="!isInterventionMessage(interventionId)">
      <div><span class="motif-icon motif-message"></span></div>
      <div class="int-desc">
        <p id="review-title" class="review-title" [innerHTML]="callInterventionLabels.needtoReview"></p>
        <div class="footer-desc" tcp-content-transform-cmp [content]="callInterventionLabels.footerContent" (onLinkClick)="handleContentPlaceholderLinkClickEvent($event)">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn continue btn-primary modal-btn" id="interventionsClose" (click)="closeModal('button')" [innerHTML]="callInterventionLabels.close"></button>
    </div>
  </div>

  <!-- Intervention Error Handling -->
  <div class="intervention-error" *ngIf="interventionMessageError">
    <div class="error-container text-center">
      <img class="error-container__img" src="{{callInterventionLabels?.errorMsgs?.pleaseTryAgainErrorImg}}" alt="please try again error image" />
      <p class="error-container__description">{{callInterventionLabels?.errorMsgs?.pageLevelErrorMessage}}</p>
    </div>
    <div class="modal-footer intervention-error__footer">
      <button class="btn continue btn-primary modal-btn" id="interventionsClose" (click)="closeModal('button')" [innerHTML]="callInterventionLabels.close"></button>
    </div>
  </div>
</div>