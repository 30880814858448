import { Component, OnInit } from "@angular/core";
import { Restriction } from "gbd-models";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { CardContent } from "../model/labels";

@Component({
  selector: "app-dashboard-benefit-card",
  templateUrl: "./benefit-card.component.html",
  styleUrls: ["./benefit-card.component.scss"],
})
export class BenefitCardComponent implements OnInit {
  public content: CardContent;
  public benefitsMenuData: any;
  public isDashboard: boolean = true;
  public isTermedUser: boolean = false;

  constructor(private jsonService: JsonContentService) {}
  ngOnInit() {
    this.jsonService.getJSON("new-dashboard").subscribe((data) => {
      this.content = data.dashboard.benefitCardLabels;
      this.benefitsMenuData = data.dashboard.benefitsData;
       // show/hide menu based on restrictions
       this.benefitsMenuData.forEach(item => {
        if (item.restrictions.length > 0) {
          item.show = !item.restrictions.every((benefitsRestrictions) =>
            data.restrictions.includes(benefitsRestrictions)
          );
        }
      });
      this.isTermedUser = this.jsonService.hasRestriction(Restriction.TERMED_MEMBER_LESS_THAN_TWO_YEARS, data.restrictions);
    });
    this.isDashboard = true;
  }
}
