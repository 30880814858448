import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { WidgetConfig } from '@anthem/mbrportal/shared/components/contentRenderer.content';
import { ContentPlaceholder } from '@anthem/mbrportal/shared/public_api';
import { CallInterventions } from '@sydney/models';
import { MessageCategoryType } from 'gbd-models';
import { Subscription } from 'rxjs';
import { ChatbotService } from 'sydmed/src/app/sydmed-shared/chatbot-service/chatbot.service';
import { LiveChatService } from '../../secure/chat/services/live-chat.service';
import { DasboardService } from '../../secure/dashboard/services/dashboard.service';
import { AdobeAnalyticsService } from '../adobe-datalayer/adobe-analytics.service';
import { InterventionAnalyticsTags } from './models/analyticTags';

@Component({
  selector: 'call-interventions-modal',
  templateUrl: './call-interventions-modal.component.html',
  styleUrls: ['./call-interventions-modal.component.scss']
})
export class CallInterventionsModalComponent implements OnInit {
  @Input() callInterventionLabels: any;
  @Input() interventionId: string;
  @Input() interventionsData: any;
  @Output() onDismissModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public router: Router,
    private dashBoardSvc: DasboardService,
    private liveChatService: LiveChatService,
    private chatBotSvc: ChatbotService,
    private adobeAnalyticsSvc: AdobeAnalyticsService
  ) {}

  isInterventionModalVisible: boolean = false;
  interventionStatus: string;
  isLoading: boolean = false;
  isLiveChatEnabled: boolean = true;
  interventionMessageError: boolean = false;
  private navigationSubscription: Subscription;

  ngOnInit(): void {
    this.loadAndOpenModal();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.dismissModal();
      }
    });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  loadAndOpenModal() {
    this.isInterventionModalVisible = true;
    if (!this.interventionsData) {
      this.loadInterventionData();
    }
  }

  private async loadInterventionData() {
    this.isLoading = true;
    let interventionId: string = this.interventionId;
    if (interventionId.includes('MDCD_CL_OON_01')) {
      this.isLiveChatEnabled = false;
    }
    try {
      if (this.isInterventionMessage(this.interventionId)) {
        interventionId = this.interventionId.replace(`${MessageCategoryType.INTERVENTION.toLowerCase()}.`, '');
      }
      this.interventionsData = await this.dashBoardSvc.getIntervention(interventionId);
      this.processInterventionData(this.interventionsData);
    } catch (error) {
      this.interventionMessageError = true;
      this.handleAnalyticsFromInterventionResponseError(error);
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  private handleAnalyticsFromInterventionResponseError(error: any) {
    if (error.status === 404) {
      const interventionAnalyticData: InterventionAdobeAnalytics = {
        errorCode: `${InterventionAnalyticsTags.uuidNotFound}`
      };
      this.setAnalyticsData(InterventionAnalyticsTags.interventionErrorCode, interventionAnalyticData);
    } else {
      const interventionAnalyticData: InterventionAdobeAnalytics = {
        errorCode: `${InterventionAnalyticsTags.apiFailure}`
      };
      this.setAnalyticsData(InterventionAnalyticsTags.interventionErrorCode, interventionAnalyticData);
    }
  }

  private processInterventionData(interventionResponse: any) {
    this.interventionStatus = interventionResponse.status;
    const intervention: InterventionAdobeAnalytics = {
      id: this.interventionsData.id,
      templateId: this.interventionsData.templateId
    };
    if (interventionResponse.status === CallInterventions.InterventionStatus.UNREAD) {
      this.updateInterventionStatus(CallInterventions.InterventionStatus.READ);
    }
    this.setAnalyticsData(InterventionAnalyticsTags.interventionDataLoad, intervention);
  }
  private async updateInterventionStatus(status: CallInterventions.InterventionStatus) {
    try {
      const updateInterventionStatusResponse = await this.dashBoardSvc.updateIntervention({
        id: this.interventionId ? decodeURIComponent(this.interventionId) : '',
        status: CallInterventions.InterventionStatus.READ
      });
      if (updateInterventionStatusResponse.status === 200 && status === CallInterventions.InterventionStatus.READ) {
        //do this later
      }
    } catch (error) {
      //Log the error
    }
  }
  onCardClick(cardContent: WidgetConfig) {
    if (cardContent?.action) {
      const intervention = {
        id: this.interventionsData.id,
        templateId: this.interventionsData.templateId,
        destination: cardContent.action,
        action: `${cardContent.adobeTag}`
      };
      this.setAnalyticsData(InterventionAnalyticsTags.interventionClickEvent, intervention);
    }
    this.dismissModal();
  }

  public handleContentPlaceholderLinkClickEvent(placeholder: ContentPlaceholder) {
    const intervention = {
      id: this.interventionsData.id,
      templateId: this.interventionsData.templateId,
      action: `${InterventionAnalyticsTags.interventionModal} - ${placeholder.analyticsData?.['linkText']} - ${this.interventionsData.templateId} - ${this.interventionsData.id}`
    };
    if (placeholder.staticAttributes) {
      this.setAnalyticsData(placeholder.staticAttributes?.['data-track-analytics'], intervention);
    }
    this.dismissModal();
  }

  public handleContentPlaceholderLiveChatLinkClickEvent() {
    const intervention = {
      id: this.interventionsData.id,
      templateId: this.interventionsData.templateId,
      action: `${InterventionAnalyticsTags.interventionModal} - ${InterventionAnalyticsTags.liveChatLink} - ${this.interventionsData.templateId} - ${this.interventionsData.id}`
    };
    this.setAnalyticsData(InterventionAnalyticsTags.interventionClickEvent, intervention);

    this.dismissModal();
    this.liveChatService.hasTopicsDisabled = true;
    if (this.interventionsData) {
      let chatTopic = this.callInterventionLabels.liveChatFooterContent.chatWithLiveAgent;
      if (chatTopic) {
        this.chatBotSvc.setChatOption({ topic: chatTopic[this.interventionsData.templateId] });
      }
    }
    this.chatBotSvc.launchChatBot();
  }

  closeModal(event?: any) {
    if (this.interventionMessageError) {
      const tag = event === 'button' ? InterventionAnalyticsTags.errorModalCloseButton : InterventionAnalyticsTags.errorModalXIcon;
      this.setAnalyticsData(tag);
      this.dismissModal();
    }
    let action;
    if (event === 'button') {
      action = `${InterventionAnalyticsTags.interventionModal} - ${InterventionAnalyticsTags.closeButton} - ${this.interventionsData.templateId} - ${this.interventionsData.id}`;
    } else {
      action = `${InterventionAnalyticsTags.interventionModal} - ${InterventionAnalyticsTags.closeIcon} - ${this.interventionsData.templateId} - ${this.interventionsData.id}`;
    }
    const intervention: InterventionAdobeAnalytics = {
      id: this.interventionsData.id,
      templateId: this.interventionsData.templateId,
      action: action
    };
    this.setAnalyticsData(InterventionAnalyticsTags.interventionClickEvent, intervention);
    this.dismissModal();
  }

  private dismissModal() {
    this.onDismissModal.emit();
  }

  /**
   * Checks if a message is an intervention message.
   * @param messageUid - The unique identifier of the message.
   * @returns True if the message is an intervention message, false otherwise.
   */
  isInterventionMessage(messageUid?: string): boolean {
    if (messageUid) {
      return messageUid.startsWith(MessageCategoryType.INTERVENTION.toLowerCase());
    }
    return null;
  }

  private setAnalyticsData(analyticTag: string, intervention?: any) {
    if (typeof _satellite !== 'undefined') {
      this.adobeAnalyticsSvc.setInterventionData(intervention);
      _satellite.track(analyticTag);
    }
  }
}
