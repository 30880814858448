  <div class="margin-10 parent-div">
  <img
    [src]="imageSource"
    alt="apiFailureIcon"
    class="margin-bottom-30 margin-top-30 imageSize"
  />
  <h3 class="font-22 header">{{header}}</h3>
  <p *ngIf="description" class="description margin-bottom-30 margin-top-30" [innerHTML]="description"></p>
  <button
    *ngIf="primaryButtonText"
    class="btn btn-primary margin-bottom-30"
    (click)="onPrimaryButtonClick()"
  >
    {{primaryButtonText}}
  </button>
  </div>
