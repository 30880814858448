<div *ngIf="!showError">
    <div class="spinner">
        <uxd-spinner></uxd-spinner>
    </div>
    <div class="text-center"> 
        <span>
            {{labels.emulating}}
        </span>
    </div>
</div>

<div  class="ctrlHolder errorMessages container" *ngIf="showError" >
  <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
      <span [innerHTML]="error"></span>
  </div>
</div>

