import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, Input } from "@angular/core";
import { idCardContent } from "../interfaces/content";
import { IdCardService } from "../services/idCardSvc";
import { IHttpResponse } from "@anthem/mbrportal/http/interfaces/iHttpResponse";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/lib/inter-communication.service";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { ModalComponent } from "@anthem/uxd/deprecated";
import { NgForm, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PageGeneratorService } from "@anthem/mbrportal/shared/services/pageGeneratorSvc";
import { Subscription } from "rxjs";
import { Restriction } from 'node_modules/gbd-models/src/common/restrictions';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { Address, GetProfileDetailsResponse, TransientIdCardResponse } from 'gbd-models';
import {Router} from '@angular/router';
import { TransientData } from "../models/MemberContext";


/**
 * @description
 * This component is responsible for loading id card and features
 * This is an entry component
 *
 * @function
 * ngOnInit()
 * ngOnDestroy()
 * closeEmailModal(): void
 * closeMailModal(): void
 * closeSuccessMessage(): void
 * closeZoomModal(): void
 * downloadIdCard(): void
 * flipCard(): void
 * emailIdCardSubmit(): void
 * mailIdCardSubmit(): void
 * openEmailModal(): void
 * openMailModal(): void
 * onEmailEnter(keyEvent: any): void
 * onNavOptionClick(optionValue: string): void
 * openZoomModal(): void
 * printIdCard(): void
 * 
 *  @example
 * ```html
 * <data-sydmed-id-card-container-cmp></data-sydmed-id-card-container-cmp>
 * ```
 */

@Component({
  moduleId: module.id,
  selector: '[data-sydmed-id-card-container-cmp]',
  templateUrl: "./idCardsContainer.html",
  styleUrls: ["./idCards.scss"],
})
export class IdcardsContainerComponent implements OnDestroy, OnInit {
  @ViewChild("emailModal", { static: false }) emailModal: ModalComponent;
  @ViewChild("faxModal", { static: false }) faxModal: ModalComponent;
  @ViewChild("mailModal", { static: false }) mailModal: ModalComponent;
  @ViewChild("zoomModal", { static: false }) zoomModal: ModalComponent;
  @ViewChild("emailForm", { static: false }) emailForm: NgForm;
  @ViewChild("faxForm", { static: false }) faxForm: NgForm;

  address: Address = null;
  contactInfoErrorMessage: string = '';
  content: idCardContent;
  downloadErrorMessage: string = '';
  errorMessage: string = '';
  flipped: boolean = true;
  genericError: string;
  idCardBack: any;
  idCardFront: any
  idCardOption: string;
  idCardLoading: boolean = true;
  loadingDownloadResult: boolean;
  loadingModalResult: boolean;
  loadingOrError: boolean = true;
  memberDefaultEmailAddress: string;
  memberEmailAddress: string;
  memberName: string = '';
  modalErrorMessage: string = '';
  modalSuccessMessage: string = '';
  hideViewAfterAPIfailure: boolean = true;
  private sub: Subscription = new Subscription();
  hasMailId: boolean = true;
  hasEmailId: boolean = true;
  hasFaxId: boolean = true;
  hasDownload: boolean = true;
  hasPrint: boolean = true;
  faxFormValue: FormGroup;
  idCardError: boolean;
  transientIdCardService: boolean = true;
  transientIdCardResponse: TransientIdCardResponse;
  memberData: TransientData;
  isTermedMember: boolean = false;

  @Input() isDashboard: boolean = false;

  constructor(
    private router: Router,
    private idCardService: IdCardService,
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService,
    private pageGeneratorService: PageGeneratorService,
    private readonly formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Member ID Card',
    });

    this.faxFormValue = this.formBuilder.group({
      memberFaxNumber: ['', [
        Validators.required,
        Validators.minLength(10),
        FormValidators.WhiteSpace,
        Validators.maxLength(10)
      ]],
      memberRecipientName: ['', [
        Validators.required
      ]],
    });
  }

  ngOnInit() {
    this.setContent();
  }

  get memberFaxNumber() {
    return this.faxFormValue.get('memberFaxNumber');
  }

  get memberRecipientName() {
    return this.faxFormValue.get('memberRecipientName');
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * @description closeEmailModal() Responsible for closing the email modal
   * @returns {void}
   */
  closeEmailModal(): void {
    this.memberEmailAddress = this.memberDefaultEmailAddress;
    this.emailModal.hide();
  }

  /**
   * @description closeFaxModal() Responsible for closing the fax modal
   * @returns {void}
   */
  closeFaxModal(): void {
    this.faxModal.hide();
    this.faxFormValue.reset();
  }

  /**
   * @description closeEmailModal() Responsible for closing the mail modal
   * @returns {void}
   */
  closeMailModal(): void {
    this.mailModal.hide();
  }

  /**
   * @description closeSuccessMessage() Responsible for closing mail & email successmessage
   * @returns {void}
   */
  closeSuccessMessage(): void {
    if (this.hideViewAfterAPIfailure) {
      this.hideViewAfterAPIfailure = false;
    }
  }

  /**
   * @description closeZoomModal() Responsible for closing the Reason Codes modal
   * @returns {void}
   */
  closeZoomModal(): void {
    this.zoomModal.hide();
  }

  /**
   * @description downloadIdcard() Responsible for downloading ID Card
   * @returns {void}
   */
  downloadIdCard(): void {
    this.downloadErrorMessage = '';
    this.loadingDownloadResult = true;
    const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));

    if (typeof memberContext !== undefined) {
      this.idCardService.downloadImage().subscribe(
        (data: any) => {
          this.idCardService.handleResponse(data, memberContext.hcid);
          this.loadingDownloadResult = false;
        },
        (error: any) => {
          this.downloadErrorMessage = this.content.view.downloadErrorMessage;
          this.loadingDownloadResult = false;
        }
      );
    }
  }

  /**
   * @description flipCard() Responsible for flipping ID card
   * @returns {void}
   */
  flipCard(flipped: boolean): void {
    this.flipped = !flipped;
    document.querySelector('.side')?.classList.toggle('rotated');
    document.querySelector('.back')?.classList.toggle('rotated');
  }

  /**
   * @description emailIdCardSubmit() Responsible for emailing id card
   * @returns {void}
   */
  emailIdCardSubmit(): void {
    this.modalErrorMessage = '';
    this.loadingModalResult = true;
    const emailAddress: string[] = this.memberEmailAddress?.split(',').map(function(email: string) { return email !== null && email.trim(); });
    if (this.emailForm.valid && emailAddress?.length) {
      this.idCardService.sendEmail({ emailAddresses: emailAddress }).subscribe(
        (response: any) => {
          if (response.status === 200) {
            this.modalSuccessMessage = this.content.email.messages.success;
            this.closeEmailModal();
            this.loadingModalResult = false;
            setTimeout(() => {
              this.hideViewAfterAPIfailure = false;
            }, 30000);
          }
        },
        (error: IHttpResponse) => {
          this.loadingModalResult = false;
          this.modalErrorMessage = this.content.email.messages.failure;
        }
      );
    }
  }

  /**
   * @description faxIdCardSubmit() Responsible for fax id card
   * @returns {void}
   */
  faxIdCardSubmit(): void {
    this.modalErrorMessage = '';
    this.loadingModalResult = true;
    this.idCardService.sendFax(
      { faxNumbers: [this.memberFaxNumber.value], recipientName: this.memberRecipientName.value }).subscribe(
        (response: any) => {
        if (response.status === 200) {
          this.modalSuccessMessage = this.content.fax.messages.success;
          this.closeFaxModal();
          this.loadingModalResult = false;
          setTimeout(() => {
            this.hideViewAfterAPIfailure = false;
          }, 10000);
          this.faxFormValue.reset();
        }
      },
      (error: IHttpResponse) => {
        this.loadingModalResult = false;
        this.modalErrorMessage = this.content.fax.messages.failure;
      }
    );
  }

  /**
   * @description mailIdCardSubmit() Responsible for mailing id card
   * @returns {void}
   */
  mailIdCardSubmit(): void {
    this.modalErrorMessage = '';
    this.loadingModalResult = true;
    const mailSub = this.idCardService.sendMail().subscribe(
      (res: any) => {
        if (res === 204 || res === 200) {
          this.modalSuccessMessage = this.content.mail.successMessage;
          this.closeMailModal();
          this.loadingModalResult = false;
          setTimeout(() => {
            this.hideViewAfterAPIfailure = false;
          }, 30000);
        }
      },
      (error) => {
        this.modalErrorMessage = this.content.errorMsgs.notFound;
        this.loadingModalResult = false;
      }
    );
    this.sub.add(mailSub);
  }

  /**
   * @description openEmailModal() Responsible for opening the email modal
   * @returns {void}
   */
  openEmailModal(): void {
    this.hideViewAfterAPIfailure = true;
    this.modalSuccessMessage = this.modalErrorMessage = null;
    this.memberEmailAddress = this.memberDefaultEmailAddress;
    this.loadingModalResult = false;
    if(!this.memberEmailAddress) {
      this.emailForm.control.markAllAsTouched();
    }
    this.emailModal.show();
  }

  /**
   * @description openFaxModal() Responsible for opening the fax modal
   * @returns {void}
   */
  openFaxModal(): void {
    this.faxFormValue.reset();
    this.hideViewAfterAPIfailure = true;
    this.modalSuccessMessage = this.modalErrorMessage = null;
    this.loadingModalResult = false;
    this.faxModal.show();
  }

  /**
   * @description openMailModal() Responsible for opening the mail modal
   * @returns {void}
   */
  openMailModal(): void {
    this.loadingModalResult = false;
    this.hideViewAfterAPIfailure = true;
    this.modalSuccessMessage = this.modalErrorMessage = null;
    this.mailModal.show();
  }

  /**
   * @description
   * onEmailEnter() Responsible to handle ID card Email Enter key event handling
   * @param {KeyboardEvent}  keyEvent
   * @return {void}
   */
  onEmailEnter(keyEvent: any): void {
    if (!this.memberEmailAddress) {
      this.emailForm.form.controls['myEmails'].setErrors({'required': true});
    } else if (keyEvent.which === 13) {
      keyEvent.preventDefault();
      this.emailIdCardSubmit();
    }
  }

  /**
   * @description onNavOptionClick() Responsible for triggering action based on selected option
   * @param {string} optionValue
   * @returns {void}
   */
  onNavOptionClick(optionValue: string): void {
    switch (optionValue) {
      case this.content.view.print: {
        this.printIdCard();
        break;
      }
      case this.content.view.email: {
        this.openEmailModal();
        break;
      }
      case this.content.view.fax: {
        this.openFaxModal();
        break;
      }
      case this.content.view.mail: {
        this.openMailModal();
        break;
      }
      case this.content.view.download: {
        this.downloadIdCard();
        break;
      }
    }
  }

  /**
   * @description openZoomModal() Responsible for opening the zoom modal
   * @returns {void}
   */
  openZoomModal(): void {
    this.zoomModal.show();
  }

  /**
   * @description printIdCard() Responsible for printing the ID card
   * @returns {void}
   */
  printIdCard(): void {
    const cssBlock = `<style type="text/css">
      @media print {
        @page { size: landscape; }
      }
    </style>`;
    this.pageGeneratorService.init();
    this.pageGeneratorService.title = this.content.view.printTitle;
    this.pageGeneratorService.isPrint = true;
    this.pageGeneratorService.cssAsString = cssBlock;
    this.pageGeneratorService.body = this.constructPrintPage(
      this.idCardFront,
      this.idCardBack
    );
    this.pageGeneratorService.build();
    this.pageGeneratorService.generatePageInTab();
  }

  navigateToGetIdCard(): void {
    this.router.navigate(['/secure/id-card']);
  }

  /**
   * @description constructPrintPage() Responsible for create html string for Id card print
   * @returns {string}
   */
  private constructPrintPage(frontImage: string, backImage: string): string {
    return (
      "<img src='" +
      frontImage +
      "' width='350' height='220' style='margin-top:5px;margin-left:5px;float: left;border-radius: 10px; border: 2px solid #000;'/>" +
      "&#160&#160&#160" +
      "<img src='" +
      backImage +
      "' width='350' height='220' style='margin-top: 5px; float: left; margin-left:5px; border-radius: 10px; border: 2px solid #000;'/>"
    );
  }

  /**
   * @description createImageFromBlob() Responsible for getting images sfrom blob
   * @param {Blob} image
   * @param {boolean} isFrontImage
   * @returns {void}
   */
  private createImageFromBlob(image: Blob, isFrontImage: boolean): void {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        if (isFrontImage) {
          this.idCardFront = reader.result;
        } else {
          this.idCardBack = reader.result;
        }
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  /**
   * @description getIdCards() Responsible for getting ID Card
   * @returns {void}
   */
  private getIdCards(): void {
    this.contactInfoErrorMessage = '';
    this.idCardLoading = true;
    const viewCardSub: Subscription = this.idCardService
      .getIDCardImages(true)
      .subscribe(
        (response: any) => {
          this.createImageFromBlob(response.body, true);
          this.idCardFront = response;
          this.checkIfIDCardIsLoading();
        },
        (error: any) => {
          this.idCardError = true;
          this.transientIdCard();
        }
      );
      this.idCardService.getIDCardImages(false).subscribe(
        (response) => {
          this.createImageFromBlob(response.body, false);
          this.idCardBack = response;
          this.checkIfIDCardIsLoading();
        },
        (error: any) => {
          this.idCardError = true;
          this.transientIdCard();
        }
      );
      this.sub.add(viewCardSub);
      this.changeDetector.detectChanges();
      const memberInfoSub = this.idCardService.getUserInfo().subscribe(
        ( userInfo: GetProfileDetailsResponse) => {        
          const { addresses, emailAddresses, firstName, lastName } = userInfo;
          this.memberName = firstName + ' ' + lastName;
          this.memberDefaultEmailAddress = emailAddresses.primary;
          this.address = addresses.home;
        },
        (error: any) => {
          this.contactInfoErrorMessage = this.content.errorMsgs.contactInfoError;
        }
      );
      this.sub.add(memberInfoSub);
    
  }

  private transientIdCard(): void {
    if(this.idCardError) {
      this.getTransientIdCard();
    }
  }

  private checkIfIDCardIsLoading(): void {
    if (!this.idCardError) {
      this.idCardLoading = this.idCardFront && this.idCardBack ? false : true;
    }
  }

  private getTransientIdCard(): void {
    this.idCardService.getTransientIdCardInfo().subscribe(
      (response: any) => {
        this.transientIdCardResponse = response.body.transientData;
        this.transientIdCardService = false;
        this.idCardLoading = false;
      },
      (error: any) => {
        this.errorMessage =
          error.status === "404"
            ? this.content.errorMsgs.notFound
            : this.content.errorMsgs.loadingError;
        this.idCardLoading = false;
      }
    );
  }

  /**
   * @description setContent() responsible for gettingfetching content from json
   * using content service
   * @returns {void}
   */
  private setContent(): void {
    const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));

    if (typeof memberContext !== undefined) {
      this.memberData = {
        firstName: memberContext.memberName.firstName,
        lastName: memberContext.memberName.lastName,
        hcid: memberContext.hcid,
        groupId: memberContext.groupId
      }
    }
    this.genericError = this.downloadErrorMessage = '';
    this.jsonSvc.getJSON('idcards').subscribe(
      (content: idCardContent) => {
        this.content = content;
        this.hasEmailId = !this.jsonSvc.hasRestriction(Restriction.SHM_EMAIL_ID_CARD, content.restrictions);
        this.hasFaxId = !this.jsonSvc.hasRestriction(Restriction.SHM_FAX_ID_CARD, content.restrictions);
        this.hasMailId = !this.jsonSvc.hasRestriction(Restriction.SHM_MAIL_ID_CARD, content.restrictions);
        this.hasDownload = !this.jsonSvc.hasRestriction(Restriction.SHM_DOWNLOAD_ID_CARD, content.restrictions);
        this.hasPrint = !this.jsonSvc.hasRestriction(Restriction.SHM_PRINT_ID_CARD, content.restrictions);
        this.isTermedMember = this.jsonSvc.hasRestriction(Restriction.TERMED_MEMBER_LESS_THAN_TWO_YEARS, this.jsonSvc.getRestrictions());
        this.getIdCards();
      },
      (error: any) => {
        this.genericError =
          'We are unable to complete your request at this time. Please try again later or <a href="#/secure/contact-us/call-us">contact us</a> for help.';
      }
    );
  }
}
