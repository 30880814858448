<div *ngIf="noticesOfAction"class="notices-of-action-container">
  <div>
  <h2
    id="noa-header"
    tabindex="0"
    role="heading"
    aria-live="polite"
    class="section-header-two"
    [id]="noticesOfAction?.title"
  >
    {{ noticesOfAction?.title }}
  </h2>
  <div class="description margin-bottom-30" id="noticeOfActionDescription" tabindex="0">{{ noticesOfAction?.description }}</div>
  <div class="hotline" tabindex="0">
    <span id="phoneNumber">{{noticesOfAction?.hotlineNumber}}</span>
  </div>
  <div *ngIf="!noaDataNotFound" class="form-row">
          <div
            data-uxd-dropdown-cmp
            [attr.data-analytics] = "analyticsTags?.dateDropdownNoaDocuments"
            class="ant-lg-select form-item"
            name="drSelect"
            id="drSelect"
            [items]="dateRangeFilters?.options"
            required
            [defaultText]="defaultLabel"
            [labelText]="dateRangeFilters?.title"
            [(ngModel)]="dateRangeValue"
            (ngModelChange)="onDateRangeChange($event)"
          ></div>
  </div>
</div>
    
<div *ngIf="!isLoading">
    <ng-container *ngIf="selectedRangeList?.length > 0">
      <h6 *ngIf="!noaServerError && totalSelectedRangeList?.length > 10">{{noticesOfAction?.displaying}} {{this.selectedRangeList.length}} {{noticesOfAction?.of}} {{this.totalSelectedRangeList?.length}} {{noticesOfAction?.notices}}</h6>
      <h6 *ngIf="!noaServerError && totalSelectedRangeList?.length <= 10">{{noticesOfAction?.displaying}} {{this.selectedRangeList.length}} {{noticesOfAction?.notices}}</h6>
      <table
        class="table zebra-stripes motif-margin-top-5"
        [ngClass]="{ 'table-border': false }"
      >
        <tr class="table-row vgr-divider-border-bottom">
          <div class="tooltip-container" aria-describedby="tooltipNoticeDateHeader">
            <th
              class="table-header"
              scope="col"
              [innerHtml]="noticesOfAction?.tableLabel.noticeDate"
            >
            </th>
            <a
              href="javascript:void(0);"
              role="tooltip"
              class="ant-action-link motif-icon motif-help tooltip-icon"
              data-uxd-tooltip-dir
              id="tooltipNoticeDateHeader"
              [attr.aria-label]="'tooltipNoticeDateHeader'"
              [definition]="{
                htmlContent: noticeDateTooltip}"
              tabindex="0"
            >
            </a>
          </div>
          <th
            class="table-header"
            scope="col"
            [innerHtml]="noticesOfAction?.tableLabel.notice"
          ></th>
        </tr>
        <tr
          class="table-row vgr-divider-border-bottom"
          *ngFor="let row of selectedRangeList"
        >
          <td data-th="Date" scope="col" class="table-data item">
            <div class="notice-day-container" aria-describedby="tooltipNoticeDateHeader">
              <h5>{{noticesOfAction.noticeDate}}</h5>
              <a
              href="javascript:void(0);"
              role="tooltip"
              class="ant-action-link motif-icon motif-help"
              data-uxd-tooltip-dir
              id="tooltipNoticeDateHeader"
              [attr.aria-label]="'tooltipNoticeDateHeader'"
              [definition]="{
              htmlContent: noticeDateTooltip}"
              tabindex="0"
              >
              </a>
            </div>
            <span>{{ row?.date }}</span>
          </td>
          <td
            data-th="Name"
            class="table-data item end"
            (click)="onLetterClick(row)"
            (keyup.enter)="onLetterClick(row)"
            [attr.data-analytics]="'analytics-' + row.id"
            [attr.data-test-id]="'test-' + row.id"
            tabindex="0"
          >
            <div class="letter-spacing">
              <img
                [src]="noticesOfAction.pdfImage"
                alt="pdfIcon"
                class="icon"
              />
              <span class="letter">{{ noticesOfAction?.umLetter }}</span>
            </div>
          </td>
        </tr>
      </table>
      <div *ngIf="showLoadMoreButton" class="button-container">
        <button
        type="button"
        class="btn btn-secondary margin-bottom-30"
        (click)="onLoadMoreClick()"
        id="loadMore"
      >
        {{ noticesOfAction?.loadMore }}
      </button>
      </div>
    </ng-container>
    <div class="margin-top-40" *ngIf="!(selectedRangeList?.length)">
      <div data-uxd-alert-cmp-v2 *ngIf="(noaDataNotFound && !noaServerError) || displayList?.length" [alert]="{alertType: 'information'}"
      id="noticesNotFound" role="alert">
      <span [innerHTML]="noticesOfAction?.errorMessage"></span>
    </div>
  <div *ngIf="(noaServerError && !noaDataNotFound)" class="api-failure-div">
    <app-api-failure
    [imageSource] = "noticesOfAction.apiFailureError.image"
    [header]="noticesOfAction?.apiFailureError.header"
    [description]="noticesOfAction?.apiFailureError.description"
    [primaryButtonText]="noticesOfAction?.apiFailureError.buttonText"
    (primaryButtonClick) = "onClickContactUs()"></app-api-failure>
  </div>
</div>
</div>
<div class="ant-margin-top-60 loading-spinner-alignment" *ngIf="isLoading">
  <uxd-spinner></uxd-spinner>
</div>
</div>
<ng-template #fileDownloadFailModal>
  <app-api-failure
  [imageSource] = "noticesOfAction.downloadFileApiFailureError.image"
  [header]="noticesOfAction?.downloadFileApiFailureError.header"
  [description]="noticesOfAction?.downloadFileApiFailureError.description"
  [primaryButtonText]="noticesOfAction?.downloadFileApiFailureError.buttonText"
  (primaryButtonClick) = "onClickCloseDownloadApiFailureModal()"></app-api-failure>
</ng-template>
<ng-template #noticeDateTooltip>
  <div [innerHTML]="noticesOfAction?.tableLabel.noticeDateTooltip">
  </div>
</ng-template>