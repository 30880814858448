import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { Restriction } from 'gbd-models';
import { Router, NavigationEnd } from '@angular/router';
import {DocumentHubLabels, DocumentsHubAnalyticsTags} from './models/labels';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-document-hub',
  templateUrl: './document-hub.component.html',
  styleUrls: ['./document-hub.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentHubComponent implements OnInit {
  public sidebarContent: any;
  public hasForms: boolean = false;
  public hasNoticeOfAction: boolean = false;
  public hasPlanDocuments: boolean = false;
  public analyticsTags: DocumentsHubAnalyticsTags;
  public header: string;
  public selectedItem: string = '/secure/document-hub/plan-documents';
  public isCaregiver: boolean = false;
  private prevUrl: string;
  showMobileView: string = 'back-arrow-onload';
  constructor(
    private interCommunicationService: InterCommunicationService,     
    private jsonSvc: JsonContentService,
    private router: Router,
    private memberRepresentativeService: MemberRepresentativeService
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.prevUrl = event.url;
      });
    this.hasForms = !this.jsonSvc.hasRestriction(Restriction.PLAN_DOCUMENTS_AND_FORMS, this.jsonSvc.getRestrictions());
    this.hasNoticeOfAction =!this.jsonSvc.hasRestriction(Restriction.SHM_NOTICES_OF_ACTION, this.jsonSvc.getRestrictions());
    this.hasPlanDocuments = !this.jsonSvc.hasRestriction(Restriction.SHM_PLAN_DOCUMENTS, this.jsonSvc.getRestrictions());
    this.isCaregiver = this.memberRepresentativeService.isCaregiver

    this.header = this.hasForms ? 'Documents & Forms' : 'Documents';
    this.interCommunicationService.raiseEvent({
        title: 'HEADER_TITLE',
        message: this.header
      });
}

  setPageNavigation(url) {
    this.selectedItem = url;
    this.router.navigateByUrl(url);
    this.goToContent();
  }

  ngOnInit(): void {
    this.jsonSvc.getJSON('document-hub').subscribe((res:DocumentHubLabels) => {
      this.sidebarContent = res.sidebar;
      this.analyticsTags  = res.analyticsTags;
    });
    const ua = navigator.userAgent;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      if(this.prevUrl === '/secure/document-hub') {
        this.goToMenu();
      }
      else {
        this.goToContent();
      }
    }
  }
  goToContent() {
    this.showMobileView = 'side-menu-click';
  }
  // For mobile view - when left arrow is clicked
  goToMenu() {
    this.showMobileView = 'back-arrow-onload';
  }

}