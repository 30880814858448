import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AlertType } from '@anthem/uxd/alert';
import { Restriction } from 'gbd-models';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';


@Component({
  selector: 'app-call-us',
  templateUrl: './call-us.component.html',
  styleUrls: ['./call-us.component.scss']
})
export class CallUsComponent implements OnInit {

  public labels;
  public groupId: string;
  public showCallBackSection: boolean = true;
  public showNurseHelpLine: boolean = true;
  public showTechnicalSupport: boolean = true;
  public showCarelonRxTopic: boolean = true;
  public alertType = AlertType;

  constructor(
    private jsonSvc: JsonContentService,
    private router: Router
  ) { }

  ngOnInit() {
    const carelonRxRestrictions = [ Restriction.SHM_AHD, Restriction.TERMED_MEMBER_LESS_THAN_TWO_YEARS];
    this.jsonSvc.getJSON('contact-us').subscribe(data => {
      this.labels = data.contactUs.labels.callUs;
      for (const i in this.labels) {
        if (typeof(this.labels[i]) === "object") {
          this.labels[i] = this.labels[i][JSON.parse(sessionStorage.getItem("sydMedMemberContext")).planCode]
        }
      }
      if(this.jsonSvc.hasRestriction(Restriction.SHM_CLICK_TO_CALL, this.jsonSvc.getRestrictions())){
        this.showCallBackSection = false;
      }
      if(this.jsonSvc.hasRestriction(Restriction.SHM_NURSE_HELP_LINE, this.jsonSvc.getRestrictions())){
        this.showNurseHelpLine = false;
      }
      if(this.jsonSvc.hasRestriction(Restriction.SHM_CALL_SUPPORT, this.jsonSvc.getRestrictions())){
        this.showTechnicalSupport = false;
      }
      if(carelonRxRestrictions.some(restriction => this.jsonSvc.hasRestriction(restriction, this.jsonSvc.getRestrictions()))){
        this.showCarelonRxTopic = false;
      }
    });
    
    if (JSON.parse(window.sessionStorage.getItem('sydMedMemberContext')).groupId === "MNMMPBBS") {
      this.showCallBackSection = false;
    }
  }

  scheduleCallClickHandler() {
    this.router.navigate([this.labels.ContactUsScheduleACallUrl]);
  }

  onIdCardLinkClick(){
    this.router.navigate(['/secure/id-card']) 
  }
  

}
