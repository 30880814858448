import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Features } from '@anthem/mbrportal/common';
import { ContentHelper, EventHandler, IWindow, WCS_ATTRIBUTES } from '@anthem/mbrportal/core';
import { AppEvents, AppSession, IAppSession } from '@anthem/mbrportal/main';
import { ListFilterPipe, SPECIALTY_TRUE_PATIENT_RESPONSIBILITY, ScrollToViewSvc, TEMP_SPECIALTY_TRUE_RESPONSIBILITY_COST_11112022 } from '@anthem/mbrportal/shared';
import { DateUtility, Logger, NavigationHelper } from '@anthem/mbrportal/utility';
import { Subscription } from 'rxjs';

import { PlaceOrderModelV2 } from '@anthem/mbrportal-pharmacy/models/placeOrderModelV2';
import { PrescriptionModelV2 } from '@anthem/mbrportal-pharmacy/models/prescriptionModelV2';
import { PrescriptionSortModelV2 } from '@anthem/mbrportal-pharmacy/models/prescriptionSortModelV2';
import { StorePickUpDateTimeModel } from '@anthem/mbrportal-pharmacy/models/storePickUpDateTimeModel';
import { PrescriptionDataServiceV2 } from '@anthem/mbrportal-pharmacy/services/prescriptionDataSvcv2';
import { OpportunityDataServiceV2 } from '@anthem/mbrportal-pharmacy/services/rxOpportunityDataSvcV2';
import { VendorOpportunityDataService } from '@anthem/mbrportal-pharmacy/services/vendorOpportunityDataService';

import { PrescriptionListModelV2 } from '@anthem/mbrportal-pharmacy/models/prescriptionListModelV2';
import { CvsPharmacyService } from '@anthem/mbrportal-pharmacy/services/cvsPharmacySvc';
import { PrescriptionHomeComponentV2 } from '@anthem/mbrportal-pharmacy/components/prescriptionHomeCmpV2';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Restriction } from 'gbd-models';
import { Router } from '@angular/router';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { ahdPharmacyService } from '../services/ahd-pharmacy.service';
import { AhdPrescriptionService } from '@anthem/mbrportal-pharmacy/services/ahdPrescriptionSvc';
import { LeftNavigationService } from '../services/left-navigation.service';
import { AhdPharmacyRoutes } from '../ahd-pharmacy-routes.enum';
import { AHDFeatures, DEFAULT_DATE_RANGE_6MONTH } from '../models/ahd-landing-page.model';
import { SessionStorage } from 'sydmed/libs/storage-service/src/public-api';
import { VendorReviewTransferModel } from '@anthem/mbrportal-pharmacy/models/vendorReviewTransferModel';
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/public-api";
import { PBM_ENHANCED_ONE_CLICK_FLOW } from '@anthem/mbrportal-pharmacy/values/cvsConstants';

@Component({
  moduleId: module.id,
  selector: "app-ahd-prescription-home",
  templateUrl:
    "../../../../../../node_modules/@anthem/mbrportal-pharmacy/views/prescriptionHomev2.html",
  styleUrls: ["./ahd-prescription-home.component.scss"],
  host: {
    "[class.container]": "widgetConfig.isMini",
  },
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }, { provide: CvsPharmacyService, useClass: ahdPharmacyService },
  { provide: AhdPrescriptionService, useClass: ahdPharmacyService }],
})
export class AHDPrescriptionHomeComponent extends PrescriptionHomeComponentV2 implements OnInit, OnDestroy {
  //For loading mock prescriptions data
  //result: IMember[] = AHDPrescriptionsMocK.ingenioPrescriptionsMockData.memberPrescriptions as IMember[];
  hasBioPlus: boolean = false;
  private titleSubscription: Subscription;
  constructor(
    _logger: Logger,
    _contentHelper: ContentHelper,
    @Inject(AppSession) _appSession: IAppSession,
    _cvsPharmacySvc: CvsPharmacyService,
    protected prescriptionDataService: PrescriptionDataServiceV2,
    _prescriptionListModel: PrescriptionListModelV2,
    private prescriptionModel: PrescriptionModelV2,
    public vendorReviewTransferModel: VendorReviewTransferModel,
    _prescriptionSortModel: PrescriptionSortModelV2,
    _navhelper: NavigationHelper,
    _listFilter: ListFilterPipe,
    private _changeDetector: ChangeDetectorRef,
    _opportunityDataService: OpportunityDataServiceV2,
    vendorOpportunityDataService: VendorOpportunityDataService,
    _renderer: Renderer2,
    _scrollToViewSvc: ScrollToViewSvc,
    _placeOrderModel: PlaceOrderModelV2,
    private titleService: InterCommunicationService,
    protected eventHandler: EventHandler,
    _dateUtil: DateUtility,
    _storePickUpDateTimeModel: StorePickUpDateTimeModel,
    @Inject(WCS_ATTRIBUTES) _attributes: { [k: string]: string },
    @Inject(DOCUMENT) _document: Document,
    @Inject("Window") _window: IWindow,
    private jsonSvc: JsonContentService,
    private router: Router,
    private leftNavService: LeftNavigationService
  ) {
    _appSession.timeStamp = new Date();
    /*Adding PHARMACY_BLANK_FILLDATE flag to see when the last fill date is blank in the prescription list.
     THis is being checked in prescriptionListModelV2.ts 
    const prescriptionObj: IPrescriptionDetails = this._appSession?.metaData?.features?.includes(PHARMACY_BLANK_FILLDATE) */
    _appSession.metaData.features.push(AHDFeatures.PHARMACY_BLANK_FILLDATE);

    super(
      _logger,
      _contentHelper,
      _appSession,
      _cvsPharmacySvc,
      prescriptionDataService,
      _prescriptionListModel,
      prescriptionModel,
      vendorReviewTransferModel,
      _prescriptionSortModel,
      _navhelper,
      _listFilter,
      _changeDetector,
      _opportunityDataService,
      vendorOpportunityDataService,
      _renderer,
      _scrollToViewSvc,
      _placeOrderModel,
      eventHandler,
      _dateUtil,
      _storePickUpDateTimeModel,
      _attributes,
      _document,
      _window
    );
    //This flag is needed to display the prescription list widget in the landing mode by default initially the user lands on landing page so they should see the minified form of the widget.
    this.widgetConfig.isMini = this.leftNavService.isMini;
    //restriction check to enable the AHD feature for selected markets.
    this.transferToCarelonRx = !this.jsonSvc.hasRestriction(Restriction.SHM_AHD, this.jsonSvc.getRestrictions());
    if(this.transferToCarelonRx) {
      this._appSession.metaData.features.push(Features.HOME_DELIVERY);
      this._appSession.metaData.features.push(Features.AHD_PRESCRIPTIONS);
    }
    this.hasBioPlus = !this.jsonSvc.hasRestriction(Restriction.SHM_BIO_PLUS, this.jsonSvc.getRestrictions());
      if (this.hasBioPlus) {
        this._appSession.metaData.features.push( Features.BIOPLUS_SCHEDULE_REFILL);
        this._appSession.metaData.features.push( Features.SPECIALTY_PHARMACY);
        this._appSession.metaData.features.push( PBM_ENHANCED_ONE_CLICK_FLOW );
        this._appSession.metaData.features.push( TEMP_SPECIALTY_TRUE_RESPONSIBILITY_COST_11112022);
        this._appSession.metaData.features.push( SPECIALTY_TRUE_PATIENT_RESPONSIBILITY);
      }
  }

  ngAfterViewChecked() {
    this._changeDetector.detectChanges();
  }


  ngOnInit(): void {
    this.setPageTitle();
    this.widgetState.dateRange = DEFAULT_DATE_RANGE_6MONTH;
    super.intializeWidget();
    this.eventHandler.get(AppEvents[AppEvents.ORDER_REVIEW]).subscribe((orderReviewEvent) => {
        if(orderReviewEvent) {
          this.router.navigate([`${AhdPharmacyRoutes.PHARMACY}/${AhdPharmacyRoutes.ORDER_REVIEW}`]);
        }
    });
    //For loading mock prescriptions data
    //this.getPrescriptions();
  }

  //For loading mock prescriptions data
  // async getPrescriptions(sendDigitalData = false, customFocusRule?: boolean) {
  //   console.log("Calling get prescriptions child");
  //   const serverDate = this._dateUtil.getServerDate();
  //   serverDate.setDate(this._dateUtil.getServerDate().getDate() + 1);
  //   this.widgetState.isLoading = false;
  //   this.widgetState.showFilterLink = false;
  //   (this.widgetState.prescriptionStatusMessage as unknown) = null;
  //   this.widgetState.isSearchMode = false;
  //   console.log("this.result", this.result);
  //   (this.widgetState.selectedDateRangeMembersData as unknown) = this.result;
  //   if (
  //     this.transferToCarelonRx &&
  //     !this.widgetConfig.isMini &&
  //     !this.showCheckoutView
  //   ) {
  //     this.transferablePrescriptions =
  //       this.prescriptionModel.getTransferablePrescriptions(
  //         this.result,
  //         this.content.reviewTransferPrescriptionSummary
  //       );
  //   }
  //   this.widgetState.fromDate = this.widgetState.fromDate
  //     ? this.widgetState.fromDate
  //     : this._dateUtil.getDatePart(
  //         serverDate.setMonth(serverDate.getMonth() - DEFAULT_REFILL_MONTHS),
  //         "yyyy-MM-dd"
  //       );
  //   this.transformPrescriptionData(
  //     this.widgetState.selectedDateRangeMembersData as IMember[],
  //     sendDigitalData,
  //     customFocusRule
  //   );
  //   this.widgetState.isLoading = false;
  // }

  ngOnDestroy(): void {
    this.titleSubscription.unsubscribe();
    super.ngOnDestroy();
    if (!this.isActiveSession()) {
      this.clearPrescritionData();
    }
  }

  isActiveSession(): boolean {
    const isLoggedIn = JSON.parse(sessionStorage.getItem(SessionStorage.SYDMED_LOGGED_IN));
    return isLoggedIn ?? false;
  }

  clearPrescritionData() {
    this.prescriptionDataService.setCartData(null);
    this.prescriptionDataService.setPBMPrescriptionData(null);
    this.prescriptionDataService.setSPLPrescriptionData(null);
    this.prescriptionDataService.setPrescriptionSelectedData(null);
    this.prescriptionDataService.setParentPrescriptionList(null);
    this.prescriptionDataService.setSelectedLob(null);
    this.prescriptionDataService.setSessionCount(0);
  }

  setPageTitle() {
    this.titleSubscription = this.jsonSvc.getJSON('ahd-prescription-list').subscribe(content => {
      let title = content.pageTitle;
      let subHeader = '';
      if (this.isAllPrescriptionsPage()) {
        title = content.viewPrescriptionsTitle;
        subHeader = content.managePrescriptionsHeading;
      }
      this.titleService.raiseEvent({ message: title, title: 'HEADER_TITLE'});
      this.headerDetails = {
        welcomeMessage: subHeader
      };
    });
  }

  isAllPrescriptionsPage(): boolean {
    return this.router.url.includes(AhdPharmacyRoutes.ALL_PRESCRIPTIONS);
  }
}


