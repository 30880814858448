import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal, ModalRef, SidePanel } from '@anthem/uxd/modal';
import { CallInterventions } from '@sydney/models';
import { Restriction } from 'gbd-models';
import { Subject, Subscription } from 'rxjs';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';
import { SessionStorage, StorageService } from 'sydmed/libs/storage-service/src/public-api';
import { AccountAccessService } from 'sydmed/src/app/secure/shm-my-profile/account-access/services/account-access.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { MemberData } from '../../dashboard/interfaces/dashboardModals';
import { DashboardDataService } from '../../dashboard/services/dashboard-data.service';
import { DasboardService } from '../../dashboard/services/dashboard.service';
import { Constants } from '../enums/constants';
import { SeedItem } from '../model/labels';

@Component({
  selector: 'new-dashboard.component-container',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.scss']
})
export class NewDashboardContainerComponent implements OnInit, OnDestroy {
  public backgroundContainer: boolean = false;
  public content: any;
  public isCommercialEligible: boolean = false;
  public memberContextData: any;
  public memberData: MemberData;
  public isFirstTimeLogin: boolean = false;
  @ViewChild('sidePanelContent', { static: false })
  sidePanelContent: TemplateRef<HTMLElement>;
  public isTermedMember: boolean = false;
  public isTermedSwitcherMember: boolean = false;
  public isTermedUser: boolean = false;
  public sidePanelRef: ModalRef<any, any>;
  public slideDirection: any = 'right';
  public seedItems: SeedItem[] = [];
  public termedMemberCommercialEligibilityContent: any;
  public modalRef: ModalRef<any, any>;
  public enableMyPharmacy: boolean = false;
  public callInterventionLabels;
  public isInterventionModalVisible: boolean = false;
  public interventionId: string;
  public isInterventions: boolean = false;
  public _modalRef: ModalRef<any, any>;
  protected readonly _destroyed = new Subject<void>();
  @ViewChild('interventionsModal', { static: false }) interventionsModal: TemplateRef<any>;
  private accountServiceSub: Subscription;
  private contentServiceSub: Subscription;
  public isCaregiver: boolean = false;
  public isDesignee: boolean = false;
  public hasClaims: boolean = true;
  public interventionsData: any;
  public isSerendipitious: boolean = false;

  constructor(
    private accountService: AccountAccessService,
    private jsonService: JsonContentService,
    private dataService: DashboardDataService,
    private sidePanel: SidePanel,
    private route: ActivatedRoute,
    private router: Router,
    private _modal: Modal,
    private memberRepresentativeService: MemberRepresentativeService,
    private dashBoardSvc: DasboardService,
    private storage: StorageService
  ) {}
  async ngOnInit() {
    this.dataService.setMemberContextData(sessionStorage.getItem('sydMedMemberContext'));

    this.memberContextData = JSON.parse(sessionStorage.getItem('sydMedMemberContext'));
    this.isDesignee = this.dataService.isDesignee();
    this.isCommercialEligible = this.memberContextData?.commercialMemberEligibility?.commercialActive;

    this.isCaregiver = this.memberRepresentativeService.isCaregiver;

    this.isSerendipitious = !this.jsonService.hasRestriction(Restriction.SHM_SERENDIPTIOUS_INTERVENTIONS, this.jsonService.getRestrictions()) && !this.isCaregiver;
    this.isInterventions = !this.jsonService.hasRestriction(Restriction.SHM_CALL_INTERVENTION, this.jsonService.getRestrictions());
    this.route.queryParams.subscribe((params) => {
      this.interventionId = params.interventionId;
      if (params.interventionId && this.isInterventions) {
        this.isInterventionModalVisible = true;
      }
    });
    if (this.storage.getSessionItem(SessionStorage.SERENDIPITOUS_INTERVENTION) !== 'true' && this.isSerendipitious && !this.interventionId) {
      try {
        this.interventionsData = await this.dashBoardSvc.getIntervention();
        if (this.interventionsData.status === CallInterventions.InterventionStatus.UNREAD) {
          this.isInterventionModalVisible = true;
          await this.dashBoardSvc.updateIntervention({
            id: this.interventionsData?.messageId?.substring(this.interventionsData?.messageId?.indexOf('.') + 1),
            status: CallInterventions.InterventionStatus.READ
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    this.contentServiceSub = this.jsonService.getJSON('new-dashboard').subscribe((data) => {
      this.content = data.dashboard.labels;
      this.termedMemberCommercialEligibilityContent = data.dashboard.termedMemberCommercialEligibility;
      this.isCaregiver = this.memberRepresentativeService.isCaregiver;
      for (let seedItem in data.dashboard.seeds) {
        this.seedItems.push(data.dashboard.seeds[seedItem]);
      }
      this.seedItems?.forEach((seedItem) => {
        if (this.isCaregiver && (seedItem.id.includes('HNS') || seedItem.id.includes('changePCP'))) {
          seedItem.show = false;
        } else if (seedItem.restrictions.length > 0) {
          seedItem.show = !seedItem.restrictions.every((itemRestrictions) => data.restrictions?.includes(itemRestrictions));
        }
      });
      this.seedItems = this.seedItems.filter((seedItem) => seedItem.show);
      this.callInterventionLabels = data.dashboard.labels.callInterventions;
      if (this.isInterventionModalVisible && this.interventionsModal) {
        this.storage.setSessionItem({ key: SessionStorage.SERENDIPITOUS_INTERVENTION, value: 'true' });
        this._modalRef = this._modal.open(this.interventionsModal);
      }
    });

    this.isFirstTimeLogin = this.dataService.isMemberFirstTimeLogin();
    if (this.dataService.isDesignee()) {
      this.dataService.getDesigneeName().subscribe((res) => {
        this.memberData = res;
      });
    } else {
      this.memberData = this.dataService.getMemberName();
    }
    this.isTermedUser = this.jsonService.hasRestriction(Restriction.TERMED_MEMBER_LESS_THAN_TWO_YEARS, this.jsonService.getRestrictions());
    this.enableMyPharmacy = !this.jsonService.hasRestriction(Restriction.SHM_AHD, this.jsonService.getRestrictions());
    this.hasClaims = !this.jsonService.hasRestriction(Restriction.SHM_CLAIMS, this.jsonService.getRestrictions());
    this.isTermedMember = this.isTermedUser && !this.isCommercialEligible;
    this.isTermedSwitcherMember = this.isTermedUser && this.isCommercialEligible;
    this.backgroundContainer = (!this.isTermedUser && !this.isDesignee) || this.isCommercialEligible;
  }

  accessNewPlan() {
    window.open(this.termedMemberCommercialEligibilityContent?.url, '_blank');
  }

  openSlideOut() {
    this.sidePanelRef = this.sidePanel.open(this.slideDirection, this.sidePanelContent);
  }

  closePreview() {
    this.sidePanelRef.close();
  }
  closeModalRef() {
    this._modalRef.close();
    this._modalRef.onDismiss.unsubscribe();
    this._modalRef.onClose.unsubscribe();
  }
  closeIntervention() {
    this.closeModalRef();
  }
  onClick(seedItem: SeedItem) {
    if (seedItem.id === Constants.USE_YOUR_ACCOUNT) {
      this.openSlideOut();
    } else {
      this.router.navigateByUrl(seedItem.url);
    }
  }
  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    if (this.accountServiceSub) {
      this.accountServiceSub.unsubscribe();
    }
    this.contentServiceSub.unsubscribe();
  }
}
