import { Component, OnInit } from "@angular/core";
import { EmulationService } from "./emulation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthEventsService } from "sydmed/libs/authorization/authevents.service";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/lib/inter-communication.service";
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/public-api";
import { JsonContentService } from "../../sydmed-shared/content-service/json-content.service";

@Component({
  selector: "app-emulation",
  templateUrl: "./emulation.component.html",
  styleUrls: ["./emulation.component.css"],
})
export class EmulationComponent implements OnInit {
  constructor(
    private emuSvc: EmulationService,
    private router: Router,
    private route: ActivatedRoute,
    private eventSvc: AuthEventsService,
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService,
    private errCodeSvc: ErrorCodeMapperService,
  ) {
    this.interCommunicationService.raiseEvent({
      title: "HEADER_TITLE",
      message: "Emulation",
    });
  }

  private data;
  public loading = true;
  public error: string;
  public labels: any;
  public errorMessages: any;
  public showError: boolean = false;

  ngOnInit(): void {

    this.jsonSvc.getJSON('emulation').subscribe((res) => {
      this.labels = res.emulation.labels;
      this.errorMessages = res.emulation.ErrorMsgs;
    });
    this.route.queryParams.subscribe((params) => {
      this.data = {
        portalUsername: params["portalUsername"],
        anthemAssociateID: params["anthemAssociateID"],
        returnToken: true,
      };
    });

    this.emuSvc.emulateUser(this.data).subscribe((res) => {
      this.setSessionStorage(res);
      this.eventSvc.updateMenu(
        window.sessionStorage.getItem("sydMedLoggedIn").toString()
      );
      this.router.navigate(["secure/dashboard"]);
    }, (error: any) => this.handleError(error));
  }

   private handleError(error: any) {
    this.loading = false;
    this.showError = true;
    this.error = this.errCodeSvc.errorCodeMapper(error, this.errorMessages);
  }

  private setSessionStorage(res) {
    window.sessionStorage.setItem(
      "sydMedMemberContext",
      JSON.stringify(res.body)
    );
    window.sessionStorage.setItem("sydMedLoggedIn", "true");
  }
}
