import { NavigationHelper } from '@anthem/mbrportal/utility';
import { CareTeamDetail, MemberData, CareTeamContent } from '../interfaces/careCoordinatorDetail';
import { CareTeamModel } from '../model/care-team.model';
import { CareTeamService } from '../services/care-team.service';
import { Component, OnInit } from "@angular/core";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/lib/inter-communication.service";
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { default as careCoordinatorMockData } from "../assets/data/mock/care-coordinator.json";
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { PcpModel } from '../../pcp/models/pcpModel';
import { PCPDetail } from '../../pcp/interfaces/pcpDetail';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { default as currentPcpGetAPIMockData} from '../assets/data/mock/current-pcp.json';
import { Restriction, ProductCode } from 'gbd-models';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';
import { ChatbotService } from 'sydmed/src/app/sydmed-shared/chatbot-service/chatbot.service';
import CONSTANTS from '../../chat/utils/constants';


/**
 * @description
 * This component is responsible for loading all care coordinator features
 * This is an entry component
 *
 * @function
 * ngOnInit()
 * onAddUpdatePCP(): void
 * private getCareTeamDetails(): void
 * private setContent(): void
 * 
 *  @example
 * ```html
 * 	<data-sydmed-care-coordinator-cmp></data-sydmed-care-coordinator-cmp>
 * ```
 */

@Component({
  selector: "data-sydmed-care-coordinator-cmp",
  templateUrl: "./careCoordinator.html",
  styleUrls: ["../careTeamSyd.scss"],
})
export class CareCoordinatorComponent implements OnInit {
  careCoordinatorErrorStatus: number;
  careCoordinatorLoader: boolean;
  careTeamLoader: boolean;
  careTeamDetail: CareTeamDetail;
  content: CareTeamContent;
  genericError: string;
  hasCareCoordinator = true;
  isPcpLoaded: boolean = false;
  memberData: MemberData;
  pcpErrorStatus: number;
  pcpInfo: PCPDetail;
  enableChangePcp: boolean = true;
  enableSendMsg: boolean = true;
  enableCareCoordinator: boolean = true;
  enableChatWithCareCoordinator: boolean = false;
  enableCoCareCoordinator: boolean = true;
  isLockedIn = false;
  isCaregiver = false;

  constructor(
    private careTeamModel: CareTeamModel,
    private careTeamService: CareTeamService,
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService,
    private memberRepresentativeService: MemberRepresentativeService,
    private navigationHelper: NavigationHelper,
    private pcpModel: PcpModel,
    private chatBotSvc: ChatbotService
  ) {
    //TODO needs to be replaced
    this.interCommunicationService.raiseEvent({
      title: "HEADER_TITLE",
      message: "My Care Team",
    });
  }

  ngOnInit() {
    this.setContent();
    this.isCaregiver = this.memberRepresentativeService.isCaregiver;
  }

  /**
   * @description onAddUpdatePCP() to add new or upadte existing pcp
   * @retruns {void}
   */
  onAddUpdatePCP(): void {
    this.navigationHelper.navigateTo("/change-provider");
  }

  /**
   * @description getCareTeamDetails() get the current PCP, care co-ordinator and co managing coordinator details
   * @retruns {void}
   */
  private getCareTeamDetails(): void {
    this.careTeamLoader = true;
    //Get the logged in member's name and hcid
    this.memberData = this.careTeamModel.getMemberData();

    //Supress Change PCP button and care coordinator card
    this.enableChangePcp = this.content.enableChangePcp == true;
    if(this.jsonSvc.hasRestriction(Restriction.SHM_VIEW_PCP, this.content?.restrictions)){
      this.enableChangePcp = false;
    }
    this.enableSendMsg = this.content.enableSendMsg == true;
    this.enableCareCoordinator = this.content.enableCareCoordinator == true;
    this.enableCoCareCoordinator = this.content.enableCoCareCoordinator == true;

    if (this.content.isLocal) {
      this.careTeamLoader = false;
      this.pcpErrorStatus = this.careCoordinatorErrorStatus = null;
      this.pcpInfo = this.pcpModel.buildPCPDetail(currentPcpGetAPIMockData, this.content.addressAriaPrefix);  
      this.careTeamDetail = this.careTeamModel.buildCareCoordinatorDetail(careCoordinatorMockData.careCoordinator, this.content);
      this.enableChatWithCareCoordinator = !this.jsonSvc.hasRestriction(Restriction.SHM_CARE_COORDINATOR_CHAT, this.content?.restrictions);
    } else {

      //Get Current PCP
      const currentPCP: any = this.careTeamService.getCurrentPCP().pipe(catchError((error: any) => {
        this.pcpErrorStatus = error.status;
        return of(undefined)
      }));

      //Get Care coordinators
      if (this.content.enableCareCoordinator) {
        const careCoordinator = this.careTeamService.getCareCoordinator().pipe(
          catchError((error) => {
            this.careCoordinatorErrorStatus = error.status;
            return of(undefined);
          })
        );

        forkJoin([currentPCP, careCoordinator]).subscribe((results: any) => {
          if (results[0]) {
            this.isLockedIn = results[0].body.lockedIn;
            this.pcpInfo = this.pcpModel.buildPCPDetail(
              results[0].body,
              this.content.addressAriaPrefix
            );
          }
          if (results[1]) {
            if (results[1].body?.exceptionList || results[1].status === 204) {
              const errorCode: number = results[1].body?.exceptionList[0]?.code;
              this.careCoordinatorErrorStatus =
                errorCode === 1002 ? errorCode : 404;
            } else {
              this.careTeamDetail = this.careTeamModel.buildCareCoordinatorDetail(
                results[1].body.careCoordinator,
                this.content
              );
              this.enableChatWithCareCoordinator = !this.jsonSvc.hasRestriction(Restriction.SHM_CARE_COORDINATOR_CHAT, this.content?.restrictions);
            }
          }
          this.careTeamLoader = false;
        });
      } else {
        forkJoin([currentPCP]).subscribe((results: any) => {
          if (results[0]) {
            this.isLockedIn = results[0].body.lockedIn;
            this.pcpInfo = this.pcpModel.buildPCPDetail(
              results[0].body,
              this.content.addressAriaPrefix
            );
          }
          this.careTeamLoader = false;
        });
      }
    }
  }

  /**
   * @description setContent() responsible for gettingfetching content from json
   * using content service
   * @returns {void}
   */
  private setContent(): void {
    this.genericError = "";
    this.jsonSvc.getJSON("care-team").subscribe(
      (content: CareTeamContent) => {
        this.content = content;
        this.hasCareCoordinator = !this.jsonSvc.hasRestriction(Restriction.SHM_CARE_TEAM, content?.restrictions);
        this.getCareTeamDetails();
      },
      (error: any) => {
        this.genericError =
          "We are unable to complete your request at this time. Please contact member services.";
      }
    );
  }

  
  /**
   * Handle care coordinator chat from
   * Menu navigation
   * @param event 
   */
  startCareCoordinatorChat(event) {
    const isLiveChatConversation = sessionStorage.getItem("isLiveChatConversation");
    if(isLiveChatConversation !== "true"){
      sessionStorage.setItem('selectedChatTopic', CONSTANTS.CHAT_WITH_COORDINATOR);
      this.chatBotSvc.startCareCoordinatorChat(event);
    }
  }
}