import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  TemplateRef,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthEventsService } from "sydmed/libs/authorization/authevents.service";
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { LogoutService } from "./services/logout.service";
import { UrlProperties } from "sydmed/libs/url-properties/src/lib/url-properties.class";
import { Restriction, States , UnreadMessageCount} from "gbd-models";
import { ChatbotService } from '../chatbot-service/chatbot.service';
import { Locale, LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';
import CONSTANTS from "../../secure/chat/utils/constants";
import { LiveChatService } from "../../secure/chat/services/live-chat.service";

@Component({
  selector: "app-secure-navigation",
  templateUrl: "./secure-navigation.component.html",
  styleUrls: ["./secure-navigation.component.css"],
})
export class SecureNavigationComponent implements OnInit {
  public userLoggedIn: boolean = false;
  public menuLoaded: boolean = false;
  public username: string = "";
  public jsonLabels;
  public logoutLabels;
  public timedLogoutLabels;
  public blacklist;
  public memberContext;
  public restricted;
  public numUnreadMessages: number = 0;
  public chatUnreadMessages: number = 0;
  public timeoutTime: Date;
  public zipCode: string = "";
  public hasProfileRestriction: boolean = false;
  public hasLanguageRestriction: boolean;
  public isTermedUser: boolean = false;
  public locale: string = '';
  public hasCareCoordinatorChatRestriction: boolean = true;
  public hasMessageCenterRestriction: boolean = false;
  public hasNewDashboardRestriction: boolean = false;
  public isLanguageChangeRestricted: boolean = false;

  //RCP
  private menuListner: Function;
  private menuOpen: boolean = false;
  private mobileMenu: boolean = false;
  private referencesSet: boolean = false;
  private _menuEvent: EventEmitter<any>;

  private menuButton: HTMLElement;
  @ViewChildren("mainItem") mainItems: QueryList<ElementRef>;
  @ViewChildren("menuItem") menuItems: QueryList<ElementRef>;
  @ViewChild("logoutModalTemplate", { static: false })
  logoutModalTemplate: TemplateRef<any>;
  @ViewChild("timeLogoutModal", { static: false })
  timeLogoutModal: TemplateRef<any>;

  constructor(
    private router: Router,
    private eventSvc: AuthEventsService,
    private _renderer: Renderer2,
    private jsonSvc: JsonContentService,
    private logoutSvc: LogoutService,
    private changeDetector: ChangeDetectorRef,
    private http: HttpClientService,
    private _httpClient: HttpClient,
    private chatBotSvc: ChatbotService,
    private localeService: LocaleService,
    private liveChatService: LiveChatService
  ) {}

  public menu = [];
  public subLevels = [];

  ngOnInit(): void {
    this.menuButton = document.getElementById("menuButton");
    this.menuButton.setAttribute("aria-expanded", "false");
    this.menuButton.addEventListener("click", this.toggleMenu.bind(this));
    
    if(window.sessionStorage.getItem('sydMedLoggedIn') === 'true'){
      this.setJson();
    }
    
    this.eventSvc.navMenuUpdated.subscribe((data) => {
      this.updateNavMenu();
      if(sessionStorage.getItem('sydMedLoggedIn') === 'true'){
        this.setJson();
      }
    });
    
    this.subscribeToVariables();
  }

  @HostListener('mouseover', ['$event'])
  @HostListener('touchstart', ['$event'])
  getNewMessageCount(event: any) {
    if (event.target.text && event.target.text.toLowerCase().indexOf('support') >= 0) {
      this.getUnreadMessages();
      this.getChatUnreadMessages();
    }
  }

  setJson() {
    if (this.hasLanguageRestriction) {
      this.localeService.removeLocaleOverride();
    } else {
      this.localeService.setLocaleOverrideFromParameter();
    }

    const localeOverride = this.localeService.getLocaleOverride();
    if (localeOverride) {
      this.locale = localeOverride;
    }
    this.jsonSvc.getJSON("menu-gbd").subscribe((data) => {
      /*
      * if a zipcode is found in session storage then insert it 
      * to url param, else ignore and let the url param stay empty
      */
      this.zipCode = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext')).zipCode;
      if (this.zipCode) {
        if(this.zipCode.length !== 5) {
          this.zipCode = this.zipCode.substring(0,5);
        }
        let updateZipcode = JSON.stringify(data).replace(/zipcode=/g, "zipcode=" + encodeURI(this.zipCode).replace(/\D/g,''));
        data = JSON.parse(updateZipcode);
      }

      this.jsonLabels = data.Labels;
      this.blacklist = data.Blacklist;
      delete this.menu;
      this.menu = [];
      this.subLevels = [];
      for (let i in data.topLevel) {
        this.menu.push(data.topLevel[i]);
        this.subLevels.push(data.subLevels[i]);
      }

      for (let i in this.menu) {
        if (this.menu[i] && this.menu[i].hasOwnProperty("subLevel")) {
          this.subLevels.forEach(subLevelItem => {
            for (let k in subLevelItem) {
              if (subLevelItem[k].topLevelId === this.menu[i].id) {
                this.menu[i].subLevel.push(subLevelItem[k]);
              }
            }
          });
        }
      }
      this.hasProfileRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_PROFILE_MENU, this.jsonSvc.getRestrictions());
      this.hasLanguageRestriction = Boolean(this.jsonSvc.hasRestriction(Restriction.SHM_LOCALE_SPANISH, this.jsonSvc.getRestrictions()));
      this.isTermedUser = this.jsonSvc.hasRestriction(Restriction.TERMED_MEMBER_LESS_THAN_TWO_YEARS, this.jsonSvc.getRestrictions());
      this.hasCareCoordinatorChatRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_CARE_COORDINATOR_CHAT, this.jsonSvc.getRestrictions());
      this.hasMessageCenterRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_NO_MESSAGE_CENTER, this.jsonSvc.getRestrictions());
      this.hasNewDashboardRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_NEW_DASHBOARD, this.jsonSvc.getRestrictions());
      this.isLanguageChangeRestricted = this.userLoggedIn && !this.hasLanguageRestriction && !this.hasNewDashboardRestriction;            
      this.setRestrictions(this.menu);
      this.chatBotSvc.setMenuConfig(this.menu);
    });

    this.jsonSvc.getJSON("logout").subscribe((data) => {
      this.timedLogoutLabels = data.Timed_Logout.Labels;
      this.logoutLabels = data.Logout.Labels;
    });
  }

  isObject(val): boolean {
    return typeof val === "object";
  }

  ngAfterViewInit() {
    this.updateNavMenu();
  }

  subscribeToVariables() {
    this.logoutSvc.getTimeoutTime().subscribe((res) => {
      this.timeoutTime = res;
    });

    this.logoutSvc.getUserLoggedIn().subscribe((res) => {
      this.referencesSet = res;
      let href: string = window.location.href.toLowerCase();
      if (href.indexOf("public") >= 0) {
        this.userLoggedIn = false;
      } else {
        this.userLoggedIn = res;
        this.changeDetector.detectChanges();
      }
    });
  }

  updateNavMenu() {
    this.userLoggedIn = sessionStorage.getItem('sydMedLoggedIn') === 'true' ? true : false;
    this.changeDetector.detectChanges();
    this.startIdleTimer();
  }

  startIdleTimer() {
    if (this.userLoggedIn) {
      this.logoutSvc.initListener(
        this.logoutModalTemplate,
        this.timeLogoutModal
      );
      this.logoutSvc.initInterval();
    }
  }

  @HostListener("document:keyup", ["$event"])
  onDocumentFocus(event: any) {
    if (!this.menuOpen) {
      if (event.key === "Enter" && event.srcElement.id === "menuButton")
        this.openMenu();
      return;
    }

    let found = false;
    for (const m of this.mainItems as any) {
      if (
        event &&
        (event.target === m.nativeElement ||
          m.nativeElement.contains(event.target))
      ) {
        found = true;
      }
    }

    if (!found) {
      this.closeMenu();
    }
  }

  closeMenu() {
    if (!this.menuOpen) {
      return;
    }

    (this.mainItems || []).forEach((m) => {
      this._renderer.removeClass(m.nativeElement, 'active');
    });

    document.body.classList.remove("ant-menu-visible");
    if (this.menuListner) {
      this.menuListner();
    }
    this.menuOpen = false;
    this.menuButton.setAttribute("aria-expanded", "false");
    this.mobileMenu = false;
    this.menuButton.classList.add("fa-bars");
    this.menuButton.classList.remove("fa-times");
  }

  openMenu() {
    if (this.menuOpen) {
      return;
    }

    document.body.classList.add("ant-menu-visible");
    this.menuOpen = true;
    this.menuButton.setAttribute("aria-expanded", "true");
    this.menuButton.classList.add("fa-times");
    this.menuButton.classList.remove("fa-bars");
  }

  toggleMenu() {
    if (this.menuOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  mainHeadClick(
    e: any,
    mainItem: any,
    subOpt: any,
    menuLink: HTMLElement,
    menuIcon: HTMLElement
  ) {
    if (this.mobileMenu) {
      return;
    }

    if (mainItem.classList.contains("active")) {
      this.closeMenu();
      menuLink.setAttribute("aria-expanded", "false");
    } else {
      mainItem.classList.add("active");
      menuIcon.classList.remove("motif-sort-down-filled");
      menuIcon.classList.add("motif-sort-up-filled");
      this.menuListner = this._renderer.listen(
        mainItem,
        "mouseleave",
        (_e: any) => {
          if (_e.relatedTarget !== menuLink) {
            this.closeMenu();
            menuLink.setAttribute("aria-expanded", "false");
            menuIcon.classList.remove("motif-sort-up-filled");
            menuIcon.classList.add("motif-sort-down-filled");
          }
        }
      );
      menuLink.setAttribute("aria-expanded", "true");
      this.openMenu();
    }
  }

  subHeadClick(e: any, subItem: any, menuIcon: any) {
    menuIcon.classList.remove("motif-sort-up-filled");
    menuIcon.classList.add("motif-sort-down-filled");

    if (window.innerWidth <= 800) {
      if (subItem.classList.contains("ant-menu-expanded")) {
        subItem.classList.remove("ant-menu-expanded");
      } else {
        subItem.classList.add("ant-menu-expanded");
      }
    }

    if (e.target.classList.contains("ant-menu-item")) {
      this.closeMenu();
    }
  }

  messageCenterClick() {
    this.closeMenu();
    this.router.navigate(["/secure/message-center"]);
  }

  private setRestrictions(menu) {
    if (window.sessionStorage.getItem("sydMedMemberContext")) {
      this.memberContext = JSON.parse(
        window.sessionStorage.getItem("sydMedMemberContext")
      );

      //checks restrictions in member context vs restrictions from the json
      for (let memRestrictionsIndex in this.memberContext.restrictions) {
        // check for top level restrictions
        for (let topLevelItem in menu) {
          if (menu[topLevelItem]["restrictions"]) {
            for (let restriction in menu[topLevelItem]["restrictions"]) {
              if (
                this.memberContext.restrictions[memRestrictionsIndex] ===
                menu[topLevelItem]["restrictions"][restriction]
              ) {
                menu[topLevelItem].show = false;
              }
            }
          }
          // check for sublevel restrtictions
          for (let subLevelItem in menu[topLevelItem].subLevel) {
            if (menu[topLevelItem].subLevel[subLevelItem]["restrictions"]) {
              for (let restriction in menu[topLevelItem].subLevel[subLevelItem][
                "restrictions"
              ]) {
                if (
                  this.memberContext.restrictions[memRestrictionsIndex] ===
                  menu[topLevelItem].subLevel[subLevelItem]["restrictions"][
                    restriction
                  ]
                ) {
                  menu[topLevelItem].subLevel[subLevelItem].show = false;
                }
              }
            }
          }
          //Temporary to show cost share for healthy kids members
          if(this.memberContext?.planCode === "FLMCD_SIMPLY_HK" && !this.isTermedUser) {
            menu.find(topItem => topItem.id === "myPlan").subLevel.find(subItem => subItem.id === "costShare").show = true;
          }
        }
      }

      this.getUnreadMessages();
      this.getChatUnreadMessages();
      this.getEngageRestrictions(menu);
      this.menuLoaded = true;
    }
  }

  private async getEngageRestrictions(menu) {
    const featureNames = {
      HEALTHDASHBOARD: "dashboard",
      BRH: "benefitRewardHub",
      PROGRAMS: "programs",
      ACTIONPLANS: "actionPlans",
      ACTIVITYTRACKING: "activityTracking",
      BADGES: "badges",
      MYHEALTHNEWS: "myHealthNews",
      SYDNEYCOMMUNITY: "sydneyCommunity"
    };
    const result: any = await this.getConfigJson();
    if (result.body.FeatureFlags[this.memberContext?.stateLob]) {
      result.body.FeatureFlags[this.memberContext?.stateLob].forEach(
        (configFlag: any) => {
          if (
            !configFlag.enabled &&
            configFlag.featureName &&
            featureNames[configFlag.featureName]
          ) {
            menu
              .find(
                (topLevelItem) => topLevelItem.id === "myHealthDashboard"
              )
              .subLevel.find(
                (subItem) =>
                  subItem.id === featureNames[configFlag.featureName]
              ).show = false;
          }
        }
      );
    }
  }

  private async getConfigJson() {
    return this._httpClient
      .get("sydmedservices/public/content/PORTAL_ENGAGE_CONFIG", {
        observe: "response",
      })
      .toPromise();
  }

  getUnreadMessages() {
    this.http
      .getObservable(UrlProperties.UrlProperties.endPoints.landingPage.messages)
      .subscribe((response: any) => {
        if (response && response.body && (response.body.unreadCount || response.body.unreadCount === 0)) {
          this.numUnreadMessages = response.body.unreadCount;
        }
      });
  }

  getChatUnreadMessages() {
    if (this.hasCareCoordinatorChatRestriction) {
      return;
    }

    this.http
      .getObservable(UrlProperties.UrlProperties.endPoints.landingPage.chatUnreadMessage)
      .subscribe((response: any) => {
        const isTopicCareCoordinator= response?.body?.topic === CONSTANTS.CHAT_WITH_COORDINATOR;
        if(isTopicCareCoordinator){
          this.liveChatService.refreshChatMenu(true);
          this.startCareCoordinatorChat(null);
        }
        this.chatUnreadMessages = isTopicCareCoordinator ? response.body.count ?? 0 : 0;
      });
  }

  //this is for live chats future implementation, with no navigation
  liveChatPopUp(event) {
    this.chatBotSvc.launchChatBot(event, true);
  }

  logoutModal() {
    this.logoutSvc.logoutModal();
  }

  logout() {
    this.logoutSvc.logout();
  }

  profileClick() {
    this.closeMenu();
    this.router.navigate(["/secure/profile"]);
  }

  stayLoggedIn() {
    this.logoutSvc.stayLoggedIn();
  }

  languageToggle() {
    if (this.locale === '') {
      this.localeService.setLocaleOverride(Locale.ES_US);
    }
    else {
      this.localeService.removeLocaleOverride();
    }
    location.reload();
  }

  ngOnDestroy() {
    this.eventSvc.navMenuUpdated.unsubscribe();
  }

  /**
   * Handle care coordinator chat from
   * Menu navigation
   * @param event 
   */
  startCareCoordinatorChat(event){
    const isLiveChatConversation = sessionStorage.getItem("isLiveChatConversation");
    if(isLiveChatConversation !== "true"){
      sessionStorage.setItem('selectedChatTopic', CONSTANTS.CHAT_WITH_COORDINATOR);
      this.chatBotSvc.startCareCoordinatorChat(event);
    }
  }

  }
