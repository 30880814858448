export enum SessionStorage {
  AHD_CART_DATA = 'ahdCartData',
  AHD_PBM_PRESCRIPTION_DATA = 'ahdPbmPrescriptionData',
  AHD_SPL_PRESCRIPTION_DATA = 'ahdSplPrescriptionData',
  GBD_TOKEN = 'gbd-token',
  IS_MOBILE = 'isMobile',
  MARKET_DECOMMISSION = 'marketDecommission',
  MEMBER_CONTEXT = 'sydMedMemberContext',
  NEXT_URL = 'nextUrl',
  REGISTERED_PHONE_NUMBER = 'registeredPhoneNumber',
  SELECTED_CHAT_TOPIC = 'selectedChatTopic',
  SYDMED_LOGGED_IN = 'sydMedLoggedIn',
  SERENDIPITOUS_INTERVENTION = 'serendipitousIntervention'
}
