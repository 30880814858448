import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { AhdPharmacyRoutes } from '../ahd-pharmacy/ahd-pharmacy-routes.enum';
import { AHDUtils } from '../pharmacy/utils/ahd-pharmacy-utils';


@Injectable({
    providedIn: 'root',
})
export class AhdPharmacyRouteGuardService implements CanActivate {
    constructor(
        public router: Router,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Promise<boolean> {

        if ((state.url.includes(AhdPharmacyRoutes.DASHBOARD)) || (state.url.includes(AhdPharmacyRoutes.ALL_PRESCRIPTIONS)) || (state.url.includes(AhdPharmacyRoutes.ORDERS)) || (state.url.includes(AhdPharmacyRoutes.REVIEW_ORDER_DETAILS)) ) {
            if(!AHDUtils.isAHDOrBioPlusMember()) {
                this.router.navigateByUrl('/secure/dashboard');
            }
        }
        return true;
    }
}