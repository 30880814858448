// Angular Imports
import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AttachmentActionType, Restriction } from 'gbd-models';

// Sydmed SPA Imports
import { UrlProperties } from 'sydmed/libs/url-properties/src/lib/url-properties.class';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';

// Message Center Imports
import { MessageCenterLabels } from './../models/labels.interface';
import { MessageCenterErrorMessages } from './../models/error-messages.interface';
import { MessageCenterDataService } from './../message-center-data.service';

// Motif Imports
import { ModalRef } from '@anthem/uxd/modal';
import { AlertService, InlineAlertContainerComponent } from '@anthem/uxd/alert';
import { FileAttachmentService } from 'sydmed/libs/file-attachment/services/file-attachment.service';
import { FileDetails } from 'sydmed/libs/file-attachment/models/fileData';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';

import { AttachFiles, AttachmentErrorMsgsLabels, ErrorMsgs } from '../../appeals-and-grievances/models/labels';
import { HelperService } from '../../appeals-and-grievances/utils/helper.service';

@Component({
  selector: 'app-compose-message',
  templateUrl: './compose-message.component.html',
  styleUrls: ['./compose-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComposeMessageComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private dataSvc: MessageCenterDataService,
    private httpSvc: HttpClientService,
    private formBuilder: FormBuilder,
    private errCodeMap: ErrorCodeMapperService,
    public alert: AlertService,
    public fileService: FileAttachmentService,
    private changeDetctionRef: ChangeDetectorRef,
    public helperService: HelperService,
    private jsonSvc: JsonContentService
  ) {
    // GET JSON Data;
    this.getJsonData();
    this.restrictions = this.helperService.getRestrictions();
  }

  // Message Center Variables
  private subscriptions: Subscription[];
  public composeMessageForm: FormGroup;
  public labels: MessageCenterLabels;
  public errorMsgs: MessageCenterErrorMessages;
  hideComposeMessage: boolean = false;
  public username: string;
  public subjectId: object;
  public messageBody: string;
  public isSubmitDisabled: boolean;
  public subjectError: boolean;
  public showSuccessMessage: boolean;
  public fileTypes: string[];
  public attachmentActionType: AttachmentActionType;
  public attachFiles: AttachFiles;
  public attachmentErrorMsgslabels: AttachmentErrorMsgsLabels;
  public errorMsgsLabels: ErrorMsgs;
  public UploadAttachmentsEnabled: boolean;
  restrictions: string[];
  // Motif Variables
  public subjectDropDownModel: any;
  public subjectDropDownDef: any;
  private sidePanelRef: ModalRef<any, any>;
  @ViewChild('attachment') attachmentAlert: InlineAlertContainerComponent;

  ngOnInit(): void {
    this.subscriptions = [];
    this.fileService.fileList = [];
    this.labels = this.dataSvc.labels;
    this.hideComposeMessage = this.dataSvc.hideComposeMessage;
    this.errorMsgs = this.dataSvc.errorMsgs;
    this.showSuccessMessage = false;
    this.subjectId = null;
    this.isSubmitDisabled = false;
    this.subjectError = false;
    this.username = this.dataSvc.fullName;
    this.sidePanelRef = this.dataSvc.composeMessageSidePanel;
    this.subjectDropDownModel = 'Please Select';
    this.subjectDropDownDef = {
      title: 'Subject',
      options: []
    };
    this.composeMessageForm = this.formBuilder.group({
      MsgCntrMsgbody: ['', [Validators.required]]
    });

    this.getSubjects();   
    this.getErrorMsgs(); 
    this.UploadAttachmentsEnabled = this.jsonSvc.hasRestriction(Restriction.SHM_SECURE_MESSAGE_ATTACHMENT, this.restrictions);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    // Update sortDropDownModel after current change detection cycle is finished.
    Promise.resolve(null).then(() => this.subjectDropDownModel = 'Please Select');
  }

  getErrorMsgs() {
    this.jsonSvc.getJSON('appeals-and-grievances-error-messages').subscribe(data => {
      this.errorMsgsLabels = data.ErrorMsgs;
      this.attachmentErrorMsgslabels = data.AttachmentErrorMsgs;
    });
  }

  getJsonData() {
    this.jsonSvc.getJSON("message-center").subscribe((res) => {
      if (res) {
        const errorMessages = res.MessageCenter.AttachmentErrorMsgs;
        const noteDescription = res.MessageCenter.AttachmentErrorMsgs.NoteDescription;
        const enableAttachment = res.MessageCenter.EnableFileAttachment;
        this.fileTypes =  res.MessageCenter.AcceptedFileTypes;
        this.fileService.setJsonData(errorMessages, noteDescription, enableAttachment);
        this.attachFiles = res.MessageCenter.attachFiles;
        this.attachmentActionType = AttachmentActionType.SECURE_MESSAGE;
      }
    });
  }

  private getSubjects() {
    const endpoint = UrlProperties.UrlProperties.endPoints.secureMessaging.subjects;
    this.subscriptions.push(this.httpSvc.getObservable(endpoint).subscribe(
      (res: any) => {
        const subjects: Array<object> = res.body.subjects;
        const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
        const noPharmacyRestriction = memberContext.restrictions.includes('SHM_NO_PHARMACY');
        subjects.forEach((element: any) => {
          if(noPharmacyRestriction && element.name.includes('Pharmacy')) {
            return;
          } else {
            this.subjectDropDownDef.options.push({label: element.name, value: element.id});
          }
        });
      },
      err => {
        const serverErr = this.errCodeMap.errorCodeMapper(err, this.errorMsgs);
          this.alert.error(serverErr, {
            regionName: 'message-subject-alerts',
            politeness: 'assertive',
            isDissmisable: true
          });
      }
    ));
  }

  
  onFileAttachmentError(error: any): void{
    const serverErr = this.errCodeMap.errorCodeMapper(error, this.errorMsgs);
          this.alert.error(serverErr, {
            regionName: 'message-subject-alerts',
            politeness: 'assertive',
            isDissmisable: true
     });
  }

  public changeSubject(subjectId, subjectName) {
    this.subjectError = false;
    this.subjectId = subjectId;
    this.subjectDropDownModel = subjectName;
  }

  public submitMessage(composeForm) {

    if (this.subjectDropDownModel === 'Please Select') {
      this.subjectError = true;
      return;
    }

    if (composeForm &&
        this.username !== undefined &&
        this.subjectId !== undefined &&
        this.messageBody !== undefined) {
      this.isSubmitDisabled = true;
      const params = {
        from: this.username,
        subjectId: this.subjectId,
        fileAttachments: this.helperService.getFileDetails(),
        body: this.messageBody
      };

      const endpoint = UrlProperties.UrlProperties.endPoints.secureMessaging.composeMessage;
      this.subscriptions.push(this.httpSvc.postObservable(endpoint, params).subscribe(
        res => {
          this.isSubmitDisabled = false;
          this.showSuccessMessage = true;
        },
        err => {
          this.isSubmitDisabled = false;
          const serverErr = this.errCodeMap.errorCodeMapper(err, this.errorMsgs);
          this.alert.error(serverErr, {
            regionName: 'compose-message-alerts',
            politeness: 'assertive',
            isDissmisable: true
          });
        }
      ));
    }
  }

  addFile(event) {
    this.fileService.validateFile(
      this.fileTypes,
      new FileDetails(
        event.name,
        event.size,
        event.name.substring(event.name.lastIndexOf(".") + 1).toLowerCase()
      )
    );

    this.changeDetctionRef.detectChanges();
    if (this.attachmentAlert) {
      this.clearErrorAlert();
      this.alert.error(this.fileService.errorMessage, {
        regionName: "inline-alert"
      });
    }
  }

  clearErrorAlert() {
    this.attachmentAlert.dismissAlertId(0);
  }

  focusToAttach() {
    document.getElementById('attachButton').focus();
  }

  trackByFile(index: number, file: any) {
    return file;
  }

  public closeSidePanel() {
    this.helperService.clearFileAttachments();
    this.sidePanelRef.close();
  }

  public delete() {
    this.closeSidePanel();
  }

  get MsgCntrMsgbody() {
    return this.composeMessageForm.get('MsgCntrMsgbody');
  }
}
