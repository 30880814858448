import {Component, OnInit, ViewEncapsulation, Input, ViewChild, TemplateRef} from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { JsonContentService } from '../../../sydmed-shared/content-service/json-content.service';
import { DocumentHubLabels } from '../models/labels';
import { DocumentHubService } from '../services/document-hub.service';
import { DocumentMetadata, Restriction } from 'gbd-models';
import { Router } from '@angular/router';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ModalRef, Modal } from '@anthem/uxd/modal';
import { OpenAttachment } from '../../../sydmed-shared/open-attachment-helper/open-attachment-helper';

@Component({
  selector: 'app-plan-documents',
  templateUrl: './notices-of-action.component.html',
  styleUrls: ['./notices-of-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NoticesOfActionComponent implements OnInit {
  public noticesOfAction: any;
  dateRangeFilters: any;
  public header: string;
  public hasForms: boolean = false;
  public displayList: DocumentMetadata[];
  public noaDataNotFound: boolean = false;
  public noaServerError: boolean = false;
  public isLoading: boolean = false;
  public downloadFileLoader: boolean = false;
  public dateRangeValue: string;
  public defaultLabel = '';
  public totalSelectedRangeList : DocumentMetadata[] = [];
  public numberOfItemToShow: number = 10;
  public selectedRangeList: DocumentMetadata[] = [];
  public showLoadMoreButton: boolean = false;
  public _modalRef: ModalRef<any, any>;
  public analyticsTags: any;
  private isMobile = this.openAttachment.isMobileDevice();
  @ViewChild('fileDownloadFailModal') fileDownloadFailModal: TemplateRef<HTMLElement>;
  @Input() content: DocumentHubLabels;
  constructor(
    private interCommunicationService: InterCommunicationService,     
    private jsonSvc: JsonContentService,
    private documentHubService: DocumentHubService,
    private httpClient: HttpClient,
    private router: Router,
    private _modal: Modal, 
    private openAttachment: OpenAttachment
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.jsonSvc.getJSON('document-hub').subscribe((res:DocumentHubLabels) => {
      this.noticesOfAction = res.noticesOfAction;
      this.dateRangeFilters = this.noticesOfAction?.dateRangeFilters;
      this.defaultLabel =  this.dateRangeFilters?.options?.[1].label;
      this.analyticsTags  = res.analyticsTags;
    });
    this.hasForms = !this.jsonSvc.hasRestriction(Restriction.PLAN_DOCUMENTS_AND_FORMS, this.jsonSvc.getRestrictions());
    this.header = this.hasForms ? 'Documents & Forms' : 'Documents';
    console.log('chloe', this.header);
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: this.header
    });
     this.documentHubService.getNOAData().subscribe(
      (response) => {
        if(response.body === null || response.body.length === 0) {
          this.noaDataNotFound = true;
          this.noaServerError = false
        }
        this.displayList = response.body;
        this.setShowLoadMoreButton();
        this.onDateRangeChange(this.dateRangeFilters?.options?.[1].value);
        this.isLoading = false;
      },
      (error) => {
          this.noaServerError = true
          this.noaDataNotFound = false;
        this.isLoading = false;
      }
    ).add(()=> {
      this.dateRangeValue = this.dateRangeFilters?.options?.[1].value;
      this.defaultLabel =  this.dateRangeFilters?.options?.[1].label;
  })
    this.showLoadMoreButton = this.displayList?.length > 0 && !this.noaDataNotFound && !this.noaServerError;
  }

  onClickContactUs() {
    this.router.navigate(["/secure/contact-us"]);
  }

  onLetterClick(row: any): void {
  // api call integration
   this.downloadFileAttachment(row);
  }

    /**
   * @Description downloadFileAttachment method used to download the files from service using dcn number 
   * @param  {} file
   * @returns void
   */
    downloadFileAttachment(file): void {
      this.isLoading = true;
      let params = new HttpParams();
      params = params.append('source', file.source);
      const endpoint = UrlProperties.UrlProperties.endPoints.secureMessaging.downloadAttachment +
        "?dcn=" + file.id;
      this.httpClient
        .get(endpoint, {
          params,
          responseType: "arraybuffer",
          observe: "response",
        })
        .subscribe(
          (response: any) => {
            const fileName = this.noticesOfAction?.umLetter;
            const url = this.openAttachment.generateUrl(response);
            if (this.isMobile) {
              const link = document.createElement('a');
              link.href = url;
              document.body.append(link);
              link.click();
              }
              else{
                window.open(url, "_blank", "noopener,noreferrer");
              }
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            this._modalRef = this._modal.open(this.fileDownloadFailModal);
          }
        );
    }
    
    onDateRangeChange(value: string) {
      const dateRangeValue = this.addMonthsToDate(value);
      this.totalSelectedRangeList = this.displayList?.filter(item => this.isDateUnderRange(item.date, dateRangeValue));
      this.numberOfItemToShow = 10;
      this.selectedRangeList = this.totalSelectedRangeList?.slice(0, this.numberOfItemToShow);
      this.setShowLoadMoreButton();
    }
    
    addMonthsToDate(value: string) {
      const currentDate = new Date();
      const months = parseInt(value.split(' ')[0], 10);
      currentDate.setMonth(currentDate.getMonth() - months);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    
    isDateUnderRange(date: string, referenceDate: string): boolean {
      const refDate = new Date(referenceDate);
      const currentDate = new Date();
      const targetDate = new Date(date);
      return targetDate >= refDate && targetDate <= currentDate;
    }

    setShowLoadMoreButton(): void {
      this.showLoadMoreButton = this.totalSelectedRangeList?.length - this.selectedRangeList?.length >= 1;
    }

    onLoadMoreClick() : void{
      this.numberOfItemToShow += 10;
      this.selectedRangeList = this.totalSelectedRangeList?.slice(0, this.numberOfItemToShow);
      this.setShowLoadMoreButton();
    }
    onClickCloseDownloadApiFailureModal(){
      this._modalRef.close();
      this._modalRef.onDismiss.unsubscribe();
      this._modalRef.onClose.unsubscribe();

    }
}