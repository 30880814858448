<div class="container motif-margin-bottom-15">
  <div class="ant-member-secure">
    <div class="motif-theme mbr-page-header ant-curve-down ant-landing-page masthead" id="greetingWidgetHeader">
      <dashboard-dismissable-alert (onCloseAlertClick)="onCloseBrandChangeAlertClick()" [labels]="content.dismissibleAlert" *ngIf="showBrandChangeAlert && content.dismissibleAlert" (onAlertUrlClick)="onBrandChangeAlertLearnMoreUrlClick()"></dashboard-dismissable-alert>
      <div class=" dashboard-gradient-container">
        <div class="gradient-rounded gradient-motion"></div>
      </div>
      <div class="dashboard-header-row">
        <div class="greetings-widget-column">
          <div data-widget-name="GreetingWidgetComponent">
            <app-welcome-greeting></app-welcome-greeting>
          </div>
          <div data-widget-name="GreetingDropdownComponent">
            <app-dashboard-alerts></app-dashboard-alerts>
          </div>
        </div>
        <div class="connect-with-care-widget-column">
          <div data-widget-name="ConnectWithCareComponent">
            <app-connect-with-care></app-connect-with-care>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cards-wrapper">
    <div class='cards-container' *ngIf="showSwitcherMessagingTile">
      <div class="card large-card card-flat card-popover">
        <div class="info-elements-box info-element-width">
          <h2 class="section-header-two" [attr.aria-label]="content?.termedMemberCommercialEligibility?.header">{{content?.termedMemberCommercialEligibility?.header}}</h2>
          <span class="info-display info-screen-text" [attr.aria-label]="content?.termedMemberCommercialEligibility?.text">
            <p>{{content?.termedMemberCommercialEligibility?.text}}</p>
          </span>
          <div class="info-link motif-margin-right-5">
            <a class="ant-action-link font-bold" href="{{commercialLoginUrl}}" target="{{content?.termedMemberCommercialEligibility?.url}}" [attr.data-analytics]="content?.termedMemberCommercialEligibility?.newPlanHomeLinkanalytics" [attr.aria-label]="content?.termedMemberCommercialEligibility?.text">
              <span class="motif-icon motif-right-round"></span>
              <span class="ant-link-text">{{content?.termedMemberCommercialEligibility?.link}}</span>
            </a>
          </div>
        </div>
        <div class="info-elements">
          <span class="info-display info-screen-img switcher-img ">
            <img [src]="content?.termedMemberCommercialEligibility?.imgSrc" [alt]="content?.termedMemberCommercialEligibility?.header">
          </span>
        </div>
      </div>
    </div>
    <div class="cards-container" *ngIf="showCards && !isCaregiver">
      <div *ngFor="let item of content?.infoScreen" class="card medium-card card-flat card-popover">
        <div class=" info-elements-box">
          <h2 class="section-header-two" [attr.aria-label]="item.Header_lbl">{{item.Header_lbl}}</h2>
          <span class="info-display info-screen-text" [attr.aria-label]="item.Text_lbl">
            <p>{{item.Text_lbl}}</p>
            <ng-container *ngIf="item?.contact_details">
              <a class="programs-card-contact-link" href="tel: {{item.contact_details?.phoneNumber}}">{{item.contact_details?.phoneNumber}}{{item.contact_details?.phoneCode}}</a>
            </ng-container>
          </span>
          <div class="info-elements">
            <span class="info-display info-screen-img">
              <img [src]="item.imgSrc" [alt]="item.Header_lbl">
            </span>
          </div>
          <div class="info-link motif-margin-right-5">
            <a class="ant-action-link font-bold" href="{{item.url}}" target="{{item.target}}" [attr.data-analytics]="item.data_analytics" [attr.aria-label]="item?.aria_description">
              <span class="motif-icon motif-right-round"></span>
              <span class="ant-link-text">{{item.link_lbl}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Pharmacy mini widget start -->
    <div *ngIf="showPharmacy && !isAHDMember">
      <div class="cards-container">
        <div class="card claims-large-card card-flat card-popover pharmacy-card motif-margin-top-14">
          <app-pharmacy-dashboard [isMini]="true"></app-pharmacy-dashboard>
        </div>
      </div>
    </div>
    <!-- Pharmacy mini widget end -->

    <!-- claims mini widget start -->
    <div *ngIf="showClaims">
      <div class="cards-container">
        <div class="card claims-large-card card-flat card-popover motif-margin-top-14">
          <data-sydmed-claims-container-cmp [isMini]="true"></data-sydmed-claims-container-cmp>
        </div>
      </div>
    </div>
    <!-- claims mini widget end -->

  </div>
</div>

<!-- Modal for NY-Managed Long Term care Integra Termed Members -->
<ng-template #nyIntegraTermedModal>
  <div id="termedInfo" class="login-modal">
    <div class="modal-header">
      <button class="close motif-icon motif-delete" id="termedInfoModalDelete" aria-label="Close Modal" (click)="closeModalRef()">
      </button>
    </div>
    <div class="modal-title">
      <h2 class="font-26" id="termedInfoModalTitle">{{nyIntegraTermedLabels?.Title}}</h2>
    </div>
    <div class="modal-body">
      <p class="font-16" id="termedInfoModalBody">{{nyIntegraTermedLabels?.Body}}</p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary modal-btn" id="termedInfoModalClose" (click)="closeModalRef()">{{nyIntegraTermedLabels?.Close}}</button>
      <button class="btn continue btn-primary modal-btn" id="termedInfoModalClaims" (click)="goToClaims()">{{nyIntegraTermedLabels?.GoToClaims}}</button>
    </div>
  </div>
</ng-template>

<!-- Modal for Market Decommission -->
<ng-template #marketDecommissionModal>
  <div class="login-modal">
    <app-dashboard-modal [labels]="content.marketDecommission" (onDismissModal)="closeMarketDecommissionModal()"></app-dashboard-modal>
  </div>
</ng-template>

<!-- Modal for Interventions -->
<ng-template #interventionsModal>
  <div class="login-modal">
    <call-interventions-modal [callInterventionLabels]="callInterventionLabels" [interventionId]="interventionId" [interventionsData]="interventionsData" (onDismissModal)="closeIntervention()"></call-interventions-modal>
  </div>
</ng-template>

<!-- Modal for LA Pharmacy Carveout modal -->
<ng-template #pharmacyVendorChangeModal>
  <div id="pharmacyVendorChange" class="login-modal">
    <div class="modal-header">
      <button class="close motif-icon motif-delete" id="pharmacyCarvoutModalDelete" aria-label="Close Modal" [attr.data-analytics]="pharmacyVendorChangeLabels.close_icon_analytics" (click)="closeModalRef()">
      </button>
    </div>
    <div class="modal-title">
      <h2 class="font-26" id="pharmacyVendorChangeTitle">{{pharmacyVendorChangeLabels.Title}}</h2>
    </div>
    <div class="modal-body">
      <p class="font-16" id="pharmacyVendorChangeBody">{{pharmacyVendorChangeLabels.Body}}</p>
    </div>
    <div class="modal-footer">
      <button class="btn continue btn-primary modal-btn" id="pharmacyVendorChangeClose" [attr.data-analytics]="pharmacyVendorChangeLabels.close_button_analytics" (click)="closeModalRef()">{{pharmacyVendorChangeLabels.Close}}</button>
    </div>
  </div>
</ng-template>

<!-- Modal for App-pop-up -->
<ng-template #appPopUpModal>
  <div class="popUpmodal ">
    <div class="modal-header">
      <div class="closePopUp" (click)="closeModal()" [attr.data-analytics]="popup?.closeAnalytics"></div>
    </div>
    <div class="modal-body">
      <span class="popUpHeader">
        {{ popup?.Header }} </span><br />
      <span class="popUpTitle">
        {{ popup?.Title }}
      </span>
    </div>
    <div class="modal-footer appInfo">
      <a class="appleLink" href="{{ popup?.appStoreLink }}" [attr.data-analytics]="popup?.appStoreAnalytics" target="_blank" rel="noopener noreferrer">
        <img src="./assets/images/app-store-badge-en.png" />
      </a>
      <a class="googleLink" href="{{ popup?.googStoreLink }}" [attr.data-analytics]="popup?.playStoreAnalytics" target="_blank" rel="noopener noreferrer">
        <img src="./assets/images/google-play-badge-en.png" />
      </a>
    </div>
  </div>
</ng-template>

<!-- Modal for Termed medicaid member -->
<ng-template #termedMedicaidMemberModal>
  <div class="login-modal">
    <div class="modal-header">
      <button class="close motif-icon motif-delete" (click)="closeTermedMemberModal()" [attr.data-analytics]="content?.termedMemberCommercialEligibility?.termedMemberPopup?.CloseIconBtnAnalytics"></button>
    </div>
    <div class="modal-body">
      <h2 class="font-26 font-weight-bold">{{ content?.termedMemberCommercialEligibility?.termedMemberPopup?.Title }}</h2><br />
      <p class="font-16">{{content?.termedMemberCommercialEligibility?.termedMemberPopup?.Body }}</p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary modal-btn" id="termedMemberModalStayHere" [attr.data-analytics]="content?.termedMemberCommercialEligibility?.termedMemberPopup?.StayHereAnalytics" (click)="closeTermedMemberModal()">{{content?.termedMemberCommercialEligibility?.termedMemberPopup?.StayHere}}</button>
      <button class="btn continue btn-primary modal-btn" id="termedInfoModalAccessNewPlans" [attr.data-analytics]="content?.termedMemberCommercialEligibility?.termedMemberPopup?.AccessNewPlanAnalytics" (click)="accessNewPlansForTermedMember()">{{content?.termedMemberCommercialEligibility?.termedMemberPopup?.AccessNewPlan}}</button>
    </div>
  </div>
</ng-template>