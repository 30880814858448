import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlanDocumentsContent } from '../interfaces/benefitsContent';
@Component({
  selector: 'benefits-plan-documents-component',
  templateUrl: './plan-documents.component.html',
  styleUrls: ['./plan-documents.component.scss']
})
export class PlanDocumentsComponent {
  @Input() content: PlanDocumentsContent;
  @Input() subTitle: boolean;
  public classId: string;
  planCode = '';
  memberContext: any;
  handBookUrlLink: string;
  isMemberMaterialLink;

  constructor(private router: ActivatedRoute) {}
  ngOnInit(): void {
    this.router.queryParams.subscribe((params) => {
      this.isMemberMaterialLink = params && params['document'] === 'member-materials';
    });

    this.memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    this.classId = this.memberContext.classId;
    const stateLobs = ['DCMCD', 'GAMCD', 'IAMCD', 'TXMCD', 'TXSET', 'TNMCD', 'MDMCD', 'NJMCD', 'WAMCD', 'NYMCD', 'ARMCD', 'MNMCD', 'MNMMP', 'NCMCD', 'WVMCD'];
    if (stateLobs.includes(this.memberContext.stateLob)) {
      this.planCode = this.memberContext.planCode;
    }
    if (this.isObject(this.content?.handbookUrl)) {
      if (!this.planCode) {
        this.handBookUrlLink = this.content.handbookUrl[this.classId.substring(0, 2)] || this.content.handbookUrl['default'];
      }
    } else {
      this.handBookUrlLink = this.content?.handbookUrl;
    }
    this.handBookUrlLink = this.isObject(this.content.handbookUrl) && this.planCode ? (this.content.handbookUrl[this.planCode] ? this.content.handbookUrl[this.planCode] : this.content.handbookUrl['default']) : this.handBookUrlLink;

    if (this.isMemberMaterialLink && this.content['memberMaterialsLink']) {
      window.location.href = this.content['memberMaterialsLink'];
    }
  }
  isObject(val): boolean {
    return typeof val === 'object';
  }
}
