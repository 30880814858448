import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentHubComponent } from './document-hub.component';
import { NoticesOfActionComponent } from './notices-of-action/notices-of-action.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentFormsComponent } from "./document-forms/document-forms.component";
import { AuthGuardService } from "sydmed/libs/authorization/route-guard.service";

const routes: Routes = [
  {
    path: "secure/document-hub",
    component: DocumentHubComponent,
    children: [
      {
        path: "notices-of-action",
        pathMatch: 'full',
        component: NoticesOfActionComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'plan-documents', 
        pathMatch: 'full',
        component: DocumentsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: "forms",
        pathMatch: 'full',
        component: DocumentFormsComponent,
        canActivate: [AuthGuardService]
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentHubRoutingModule {}
