// Angular Imports
import {
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  ElementRef,
  QueryList,
  ViewChildren,
  AfterViewInit,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Subscription, forkJoin } from "rxjs";

// Sydmed SPA Imports
import { UrlProperties } from "sydmed/libs/url-properties/src/lib/url-properties.class";
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service";
import { AttachFiles, AttachmentErrorMsgsLabels, ErrorMsgs } from '../../appeals-and-grievances/models/labels';

// Message Center Imports
import { MessageCenterLabels } from "./../models/labels.interface";
import { MessageCenterErrorMessages } from "./../models/error-messages.interface";
import { MessageCenterDataService } from "./../message-center-data.service";

// Motif Imports
import { Modal, ModalRef } from '@anthem/uxd/modal';
import { AlertService, InlineAlertContainerComponent } from '@anthem/uxd/alert';
import { FileAttachmentService } from 'sydmed/libs/file-attachment/services/file-attachment.service';
import { FileDetails } from 'sydmed/libs/file-attachment/models/fileData';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { AttachmentActionType, MessageCategoryType , Restriction, UpdateMessage } from 'gbd-models';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';
import { HelperService } from '../../appeals-and-grievances/utils/helper.service';
import { CallInterventionsLabels, MessageUidExtension } from "../models/intervention.model";

enum FolderName {
  ARCHIVED = "archived",
  CHAT_TRANSCRIPTS = "chatTranscripts",
  DELETED = "deleted",
  INBOX = "inbox",
}
@Component({
  selector: "app-message-detail",
  templateUrl: "./message-detail.component.html",
  styleUrls: ["./message-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MessageDetailComponent
  implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private dataSvc: MessageCenterDataService,
    private httpSvc: HttpClientService,
    private errCodeMap: ErrorCodeMapperService,
    private formBuilder: FormBuilder,
    private modal: Modal,
    public alert: AlertService,
    private httpClient: HttpClient,
    public fileService: FileAttachmentService,
    private changeDetctionRef: ChangeDetectorRef,
    private jsonSvc: JsonContentService,
    private memberRepresentativeService: MemberRepresentativeService,
    public helperService: HelperService,
    public element : ElementRef
  ) {
    // GET JSON data
    this.getJsonData();
    this.restrictions = this.helperService.getRestrictions();
  }


  @ViewChild("modalTemplate", { static: false })
  modalTemplate: TemplateRef<any>;
  @ViewChild("attachment") attachmentAlert: InlineAlertContainerComponent;
  selectedIndex;
  @ViewChild("errorAlert") errorAlert: InlineAlertContainerComponent;
  @ViewChildren("messageBody") messageBody: QueryList<ElementRef>;
  @ViewChild('interventionsModal', {static: false}) interventionsModal: TemplateRef<any>;//Intervention Model

  public attachmentActionType: AttachmentActionType;
  public labels: MessageCenterLabels;
  public errorMsgs: MessageCenterErrorMessages;
  public downloadAttachmentRestriction: boolean;
  private subscriptions: Subscription[];
  private transcriptCategoryType: MessageCategoryType;
  private hasMessageReply: boolean;
  public messageReplyForm: FormGroup;
  public messageDetails: any;
  public fromValue: string;
  public replyMsgs: Array<object>;
  private folder: string;
  public inboxOptions: boolean;
  public archivedOptions: boolean;
  public messageReply: string;
  public isSubmitDisabled: boolean;
  public isMessageDeleting: boolean;
  public loading: boolean;
  public showSuccessMessage: boolean;
  public viewOnly: boolean = false;

  private sidePanelRef: ModalRef<any, any>;
  private modalRef: ModalRef<any, any>;
  messageBodyHeight = [];
  public fileTypes: string[];
  public isEnterpriseMessage: boolean = false;
  public isDesignee: boolean = false;
  public isTermedUser: boolean = false;
  public attachFiles: AttachFiles;
  public attachmentErrorMsgslabels: AttachmentErrorMsgsLabels;
  public errorMsgsLabels: ErrorMsgs;
  public UploadAttachmentsEnabled: boolean;
  restrictions: string[];
  public callInterventionLabels: CallInterventionsLabels; 
  public _modalRef: ModalRef<any, any>;

  careGapReplyForm = new FormGroup({
    careGap: new FormControl(""),
  });
  showDispositionReply: boolean = false;
  get careGap() {
    return this.careGapReplyForm.get("careGap");
  }

  ngOnInit(): void {
    this.attachmentActionType = AttachmentActionType.SECURE_MESSAGE;
    this.transcriptCategoryType = MessageCategoryType.CHAT_TRANSCRIPT;
    this.labels = this.dataSvc.labels;
    this.errorMsgs = this.dataSvc.errorMsgs;
    this.isDesignee = this.memberRepresentativeService.isDesignee;
    this.isTermedUser = this.dataSvc.checkForTermedRestrictions;
    this.downloadAttachmentRestriction =
      this.dataSvc.restrictions.indexOf("SHM_NO_MESSAGE_DOWNLOAD") > -1;
    this.subscriptions = [];
    this.replyMsgs = [];
    this.fileService.fileList = [];
    this.inboxOptions = false;
    this.archivedOptions = false;
    this.isSubmitDisabled = false;
    this.isMessageDeleting = false;
    this.loading = false;
    this.showSuccessMessage = false;
    this.sidePanelRef = this.dataSvc.replyMessageSidePanel;
    this.subscriptions.push(
      this.dataSvc.getfolder().subscribe((folder) => {
        this.folder = folder;
      })
    );
    this.messageReplyForm = this.formBuilder.group({
      MsgCntrMsgReply: ["", [Validators.required]],
    });

    this.getMessage();
    this.hasMessageReply = (this.inboxOptions || this.archivedOptions) && (this.messageDetails.allowReply  && this.messageDetails.messageCategoryType !== this.transcriptCategoryType);
    this.checkForEnterpriseMessage();
    this.getErrorMsgs(); 
    this.UploadAttachmentsEnabled = this.jsonSvc.hasRestriction(Restriction.SHM_SECURE_MESSAGE_ATTACHMENT, this.restrictions);
  }

  getErrorMsgs() {
    this.jsonSvc.getJSON('appeals-and-grievances-error-messages').subscribe(data => {
      this.errorMsgsLabels = data.ErrorMsgs;
      this.attachmentErrorMsgslabels = data.AttachmentErrorMsgs;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.changeDetctionRef.detectChanges();
      this.messageBody.toArray().forEach((element) => {
        this.messageBodyHeight.push(element.nativeElement.offsetHeight);
      });
    }, 1);

    this.addInterventionLinkListeners();
  }

  
  /**
 * Adds click event listeners to all anchor elements within the current element if the message is of type 'INTERVENTION'.
 * The listeners are only added if the href of the anchor element includes the intervention ID.
 */
  addInterventionLinkListeners(): void {
    const anchorElements = this.element.nativeElement.querySelectorAll('a');

    if (this.isInterventionMessage()) {
      const messageUid = this.getInterventionId();

      // For each anchor element, add a click listener if its href includes the intervention ID
      anchorElements.forEach((anchorElement: HTMLElement) => {
        this.addClickListenerIfHrefIncludesUid(anchorElement, messageUid);
      });
    }
  }

  /**
 * Checks if the message is of type 'INTERVENTION'.
 * @returns {boolean} - Returns true if the message category type is 'INTERVENTION', false otherwise.
 */
  isInterventionMessage(): boolean {
    return this.messageDetails.messageCategoryType?.toUpperCase() === MessageCategoryType.INTERVENTION;
  }

  /**
  * Retrieves the intervention ID from the message details.
  * @returns {string} - Returns the intervention ID after removing the 'INTERVENTION' prefix from the uid.
  */
  getInterventionId(): string {
    return this.messageDetails.uid?.replace(`${ MessageUidExtension.INTERVENTION }`, '');
  }

  /**
 * Adds a click event listener to an anchor element if its href includes the provided uid.
 * @param {HTMLElement} anchorElement - The anchor element to add the event listener to.
 * @param {string} uid - The uid to check for in the href of the anchor element.
 */
  addClickListenerIfHrefIncludesUid(anchorElement: HTMLElement, uid: string): void {
    const anchorHref = anchorElement.getAttribute('href');

    if (anchorHref.includes(uid)) {
      anchorElement.addEventListener('click', (event) => {
        event.preventDefault();

        // Open Intervention Model when "View more" button is clicked
        this.openInterventionModel();
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  onSubmit(form: any) {
    //TODO: API call
  }

  getJsonData() {
    forkJoin([this.jsonSvc.getJSON('message-center'), this.jsonSvc.getJSON('dashboard')])
      .subscribe(([messagecenterLabels, dashboardLabels]) => {
      if (messagecenterLabels) {
        const { AttachmentErrorMsgs, EnableFileAttachment, AcceptedFileTypes, attachFiles } = messagecenterLabels.MessageCenter;
        const errorMessages = AttachmentErrorMsgs;
        const noteDescription = AttachmentErrorMsgs.NoteDescription;
        const enableAttachment = EnableFileAttachment;
        this.callInterventionLabels = dashboardLabels.Dashboard.Labels.callInterventions;
        this.fileTypes =  AcceptedFileTypes;
        this.fileService.setJsonData(errorMessages, noteDescription, enableAttachment);
        this.attachFiles = attachFiles;
        this.viewOnly = this.jsonSvc.hasRestriction(Restriction.SHM_MESSAGE_CENTER_VIEW_ONLY, messagecenterLabels.restrictions);
      }
    });
  }

  private getMessage() {
    this.messageDetails = this.dataSvc.messageDetails;
    this.fromValue = this.dataSvc.fullName;

    const numOfMsgs = this.messageDetails.thread.length;
    for (let i = 0; i < numOfMsgs; i++) {
      const numOfAttachments =
        this.messageDetails &&
        this.messageDetails.thread[i] &&
        this.messageDetails.thread[i].fileAttachments
          ? this.messageDetails.thread[i].fileAttachments.length
          : 0;
      for (let j = 0; j < numOfAttachments; j++) {
        this.messageDetails.thread[i].fileAttachments[j].fileType = this.messageDetails.thread[i].fileAttachments[j].fileType.toLowerCase();
        if (
          this.messageDetails.thread[i].fileAttachments[j].fileName.toLowerCase().indexOf("." + this.messageDetails.thread[i].fileAttachments[j].fileType.toLowerCase()) < 0
        ) {
          this.messageDetails.thread[i].fileAttachments[j].fileName = this.messageDetails.thread[i].fileAttachments[j].fileName + "." + this.messageDetails.thread[i].fileAttachments[j].fileType.toLowerCase();
        }
      }

      this.replyMsgs.push({
        messageFrom: this.messageDetails.thread[i].from,
        messageDate: this.messageDetails.thread[i].date,
        messageBody: this.messageDetails.thread[i].body,
        // to get attachments from API
        messageAttachments: this.messageDetails.thread[i].fileAttachments,
      });
    }

    switch (this.folder) {
      case FolderName.INBOX: 
      case FolderName.CHAT_TRANSCRIPTS:
        this.inboxOptions = true;
        break;
      case FolderName.ARCHIVED:
        this.archivedOptions = true;
        break;
    }
  }

  checkForEnterpriseMessage(): void{
    const messageCategory: string = this.messageDetails?.messageCategoryType;
    if (messageCategory?.indexOf(MessageCategoryType.ENT_ECOMM_NOTIFICATION) == 0) {
        this.isEnterpriseMessage = true;
    }
  }

  public updateDetails(folderType) {
    if (folderType === "deleted") {
      this.isMessageDeleting = true;
    }
    const endpoint = UrlProperties.UrlProperties.endPoints.secureMessaging.updateMessage;
    const params: UpdateMessage = {
      folder: folderType,
      uids: [this.messageDetails.uid],
    };
    this.loading = true;
    this.httpSvc.putObservable(endpoint, params).subscribe(
      res => {
        this.isMessageDeleting = false;
        this.loading = false;
        this.dataSvc.setfolder(this.folder);
        this.sidePanelRef.close();
        this.sidePanelRef.onDismiss.unsubscribe();
        this.sidePanelRef.onClose.unsubscribe();
      },
      (err) => {
        this.isMessageDeleting = false;
        this.loading = false;
        const serverErr = this.errCodeMap.errorCodeMapper(err, this.errorMsgs);
        if (folderType === "deleted") {
          this.alert.error(serverErr, {
            regionName: "delete-message-alerts",
            politeness: "assertive",
            isDissmisable: true,
          });
        } else {
          this.callErrorAlert(serverErr);
        }
      }
    );
  }

  public sendReplyMsg(valid) {
    if (valid) {
      const params = {
        from: this.fromValue,
        fileAttachments: this.helperService.getFileDetails(),
        body: this.messageReply,
      };

      this.isSubmitDisabled = true;
      const endpoint =
        UrlProperties.UrlProperties.endPoints.secureMessaging.messages +
        "/" +
        this.messageDetails.uid +
        "/replymessages";
      this.httpSvc.postObservable(endpoint, params).subscribe(
        (res) => {
          this.dataSvc.setfolder(this.folder);
          this.isSubmitDisabled = false;
          this.showSuccessMessage = true;
        },
        (err) => {
          this.isSubmitDisabled = false;
          const serverErr = this.errCodeMap.errorCodeMapper(
            err,
            this.errorMsgs
          );
          this.callErrorAlert(serverErr);
        }
      );
    }
  }

  open() {
    this.modalRef = this.modal.open(this.modalTemplate);
  }

  handleClick() {
    this.open();
  }

  onModalClose() {
    this.modalRef.close();
    this.modalRef.onDismiss.unsubscribe();
    this.modalRef.onClose.unsubscribe();
    this.sidePanelRef.close();
    this.sidePanelRef.onDismiss.unsubscribe();
    this.sidePanelRef.onClose.unsubscribe();
  }

  closeSidePanel() {
    this.helperService.clearFileAttachments();
    this.sidePanelRef.close();
    this.sidePanelRef.onDismiss.unsubscribe();
    this.sidePanelRef.onClose.unsubscribe();
  }

  public delete() {
    this.closeSidePanel();
  }

  get MsgCntrMsgReply() {
    return this.messageReplyForm.get("MsgCntrMsgReply");
  }

  attachFile(event) {
    this.fileService.validateFile(
      this.fileTypes,
      new FileDetails(
        event.name,
        event.size,
        event.name.substring(event.name.lastIndexOf(".") + 1).toLowerCase()
      )
    );
    this.changeDetctionRef.detectChanges();
    if (this.attachmentAlert) {
      this.clearErrorAlert();
      this.alert.error(this.fileService.errorMessage, {
        regionName: "inline-alert",
      });
    }
  }

  clearErrorAlert() {
    this.attachmentAlert.dismissAlertId(0);
  }

  trackByFile(index: number, file: any) {
    return file;
  }

  downloadFile(file) {
    const endpoint =
      UrlProperties.UrlProperties.endPoints.secureMessaging.downloadAttachment +
      "?dcn=" +
      file.fileIdentifier;
    // const endpoint = 'http://localhost:3000/dev/secureMessaging' + '?dcn=' + file.fileIdentifier;
    // const endpoint = 'https://sydmed-services.dev.va.anthem.com/dev1/secure/securemessaging/downloadmessagettachment' + '?dcn=' + file.fileIdentifier;
    const httpOptions = {
      headers: new HttpHeaders({
        transactionid: "1234",
        "gbd-token": window.sessionStorage.getItem("gbd-token"),
      }),
    };

    this.httpClient
      .get(endpoint, {
        ...httpOptions,
        responseType: "arraybuffer",
        observe: "response",
      })
      .subscribe(
        (response: any) => {
          const contentType = response.headers.get("Content-Type");

          const ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
            ie11 =
              navigator.userAgent.match(/Trident\/7.0/) &&
              navigator.userAgent.match(/rv:11/),
            ieEDGE = navigator.userAgent.match(/Edge/g),
            ieVer = ie ? ie[1] : ie11 ? 11 : ieEDGE ? 12 : -1;

          const ipadIphone =
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPhone/i);

          const fileName = file.fileName + "." + file.fileType;
          const pdfBlob = new Blob([response.body], { type: contentType });

          //for Microsoft browsers (IE & Edge)
          if (ieVer > -1) {
            const nav = (window.navigator as any);
            if (nav && nav.msSaveOrOpenBlob) {
              nav.msSaveOrOpenBlob(pdfBlob, fileName);
              return;
            }
          } else {
            //for rest of the browsers
            var link = document.createElement("a");
            let url = URL.createObjectURL(pdfBlob);
            if (ipadIphone) {
              //Showing Modal due to popup blocker
              if (!window.open(url, "_blank", "noreferrer,noopener")) {
                alert("Please disable the popup blocker");
              }
            } else if ("download" in link) {
              link.href = url;
              link.download = fileName;
              link.click();
              if (file.fileType && file.fileType.toLowerCase() === "pdf") {
                window.open(url, "_blank", "noreferrer,noopener");
              }
            }
          }
        },
        (error) => {
          this.callErrorAlert(
            this.fileService.errorMessages.DownloadeAttachmentError
          );
        }
      );
  }

  showmore(index) {
    this.selectedIndex = index;
  }

  callErrorAlert(errorMessage) {
    this.changeDetctionRef.detectChanges();
    if (this.errorAlert) {
      this.errorAlert.dismissAlertId(0);
      this.alert.error(errorMessage, {
        regionName: "reply-message-alerts",
        politeness: "assertive",
        isDissmisable: true,
      });
    }
  }

  closeModalRef(){
    this._modalRef.close();
    this._modalRef.onDismiss.unsubscribe();
    this._modalRef.onClose.unsubscribe();
    this.sidePanelRef.close();
  }

  openInterventionModel():void {
    this._modalRef = this.modal.open(this.interventionsModal);
  }
}
